export default {
	'Mainland China': {
		'01-22-2020': { confirmed: 547, recovered: 28, deaths: 17 },
		'01-23-2020': { confirmed: 639, recovered: 30, deaths: 18 },
		'01-24-2020': { confirmed: 916, recovered: 36, deaths: 26 },
		'01-25-2020': { confirmed: 1399, recovered: 39, deaths: 42 },
		'01-26-2020': { confirmed: 2062, recovered: 49, deaths: 56 },
		'01-27-2020': { confirmed: 2863, recovered: 58, deaths: 82 },
		'01-28-2020': { confirmed: 5494, recovered: 101, deaths: 131 },
		'01-29-2020': { confirmed: 6070, recovered: 120, deaths: 133 },
		'01-30-2020': { confirmed: 8124, recovered: 135, deaths: 171 },
		'01-31-2020': { confirmed: 9783, recovered: 214, deaths: 213 },
		'02-01-2020': { confirmed: 11871, recovered: 275, deaths: 259 },
		'02-02-2020': { confirmed: 16607, recovered: 463, deaths: 361 },
		'02-03-2020': { confirmed: 19693, recovered: 614, deaths: 425 },
		'02-04-2020': { confirmed: 23680, recovered: 843, deaths: 490 },
		'02-05-2020': { confirmed: 27409, recovered: 1115, deaths: 562 },
		'02-06-2020': { confirmed: 30553, recovered: 1476, deaths: 632 },
		'02-07-2020': { confirmed: 34075, recovered: 1998, deaths: 717 },
		'02-08-2020': { confirmed: 36778, recovered: 2595, deaths: 804 },
		'02-09-2020': { confirmed: 39790, recovered: 3218, deaths: 904 },
		'02-10-2020': { confirmed: 42306, recovered: 3917, deaths: 1011 },
		'02-11-2020': { confirmed: 44327, recovered: 4635, deaths: 1111 },
		'02-12-2020': { confirmed: 44699, recovered: 5079, deaths: 1116 },
		'02-13-2020': { confirmed: 59832, recovered: 6213, deaths: 1368 },
		'02-14-2020': { confirmed: 66292, recovered: 7973, deaths: 1520 },
		'02-15-2020': { confirmed: 68347, recovered: 9294, deaths: 1662 },
		'02-16-2020': { confirmed: 70446, recovered: 10748, deaths: 1765 },
		'02-17-2020': { confirmed: 72364, recovered: 12455, deaths: 1863 },
		'02-18-2020': { confirmed: 74139, recovered: 14199, deaths: 2002 },
		'02-19-2020': { confirmed: 74546, recovered: 15952, deaths: 2114 },
		'02-20-2020': { confirmed: 74999, recovered: 18002, deaths: 2236 },
		'02-21-2020': { confirmed: 75472, recovered: 18693, deaths: 2236 },
		'02-22-2020': { confirmed: 76922, recovered: 22687, deaths: 2441 },
		'02-23-2020': { confirmed: 76938, recovered: 23170, deaths: 2443 },
		'02-24-2020': { confirmed: 77152, recovered: 24990, deaths: 2593 },
		'02-25-2020': { confirmed: 77660, recovered: 27650, deaths: 2663 },
		'02-26-2020': { confirmed: 78065, recovered: 30053, deaths: 2715 },
		'02-27-2020': { confirmed: 78498, recovered: 32898, deaths: 2744 },
		'02-28-2020': { confirmed: 78824, recovered: 36291, deaths: 2788 },
		'02-29-2020': { confirmed: 79251, recovered: 39279, deaths: 2835 },
		'03-01-2020': { confirmed: 79826, recovered: 42118, deaths: 2870 },
		'03-02-2020': { confirmed: 80026, recovered: 44810, deaths: 2912 },
		'03-03-2020': { confirmed: 80151, recovered: 47404, deaths: 2945 },
		'03-04-2020': { confirmed: 80271, recovered: 49955, deaths: 2981 },
		'03-05-2020': { confirmed: 80422, recovered: 52240, deaths: 3013 },
		'03-06-2020': { confirmed: 80573, recovered: 53888, deaths: 3042 },
		'03-07-2020': { confirmed: 80652, recovered: 55478, deaths: 3070 },
		'03-08-2020': { confirmed: 80699, recovered: 57320, deaths: 3097 },
		'03-09-2020': { confirmed: 80735, recovered: 58735, deaths: 3120 },
		'03-10-2020': { confirmed: 80757, recovered: 60106, deaths: 3136 },
	},
	'Hong Kong': {
		'01-22-2020': { confirmed: 0, recovered: 0, deaths: 0 },
		'01-23-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-24-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-25-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'01-26-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'01-27-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'01-28-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'01-29-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'01-30-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'01-31-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 15, recovered: 0, deaths: 0 },
		'02-03-2020': { confirmed: 15, recovered: 0, deaths: 0 },
		'02-04-2020': { confirmed: 17, recovered: 0, deaths: 1 },
		'02-05-2020': { confirmed: 21, recovered: 0, deaths: 1 },
		'02-06-2020': { confirmed: 24, recovered: 0, deaths: 1 },
		'02-07-2020': { confirmed: 25, recovered: 0, deaths: 1 },
		'02-08-2020': { confirmed: 26, recovered: 0, deaths: 1 },
		'02-09-2020': { confirmed: 29, recovered: 0, deaths: 1 },
		'02-10-2020': { confirmed: 38, recovered: 0, deaths: 1 },
		'02-11-2020': { confirmed: 49, recovered: 0, deaths: 1 },
		'02-12-2020': { confirmed: 50, recovered: 1, deaths: 1 },
		'02-13-2020': { confirmed: 53, recovered: 1, deaths: 1 },
		'02-14-2020': { confirmed: 56, recovered: 1, deaths: 1 },
		'02-15-2020': { confirmed: 56, recovered: 1, deaths: 1 },
		'02-16-2020': { confirmed: 57, recovered: 2, deaths: 1 },
		'02-17-2020': { confirmed: 60, recovered: 2, deaths: 1 },
		'02-18-2020': { confirmed: 62, recovered: 2, deaths: 1 },
		'02-19-2020': { confirmed: 63, recovered: 5, deaths: 2 },
		'02-20-2020': { confirmed: 68, recovered: 6, deaths: 2 },
		'02-21-2020': { confirmed: 68, recovered: 5, deaths: 2 },
		'02-22-2020': { confirmed: 69, recovered: 6, deaths: 2 },
		'02-23-2020': { confirmed: 74, recovered: 11, deaths: 2 },
		'02-24-2020': { confirmed: 79, recovered: 19, deaths: 2 },
		'02-25-2020': { confirmed: 84, recovered: 19, deaths: 2 },
		'02-26-2020': { confirmed: 91, recovered: 24, deaths: 2 },
		'02-27-2020': { confirmed: 92, recovered: 24, deaths: 2 },
		'02-28-2020': { confirmed: 94, recovered: 30, deaths: 2 },
		'02-29-2020': { confirmed: 95, recovered: 33, deaths: 2 },
		'03-01-2020': { confirmed: 96, recovered: 36, deaths: 2 },
		'03-02-2020': { confirmed: 100, recovered: 36, deaths: 2 },
		'03-03-2020': { confirmed: 100, recovered: 37, deaths: 2 },
		'03-04-2020': { confirmed: 105, recovered: 37, deaths: 2 },
		'03-05-2020': { confirmed: 105, recovered: 43, deaths: 2 },
		'03-06-2020': { confirmed: 107, recovered: 46, deaths: 2 },
		'03-07-2020': { confirmed: 108, recovered: 51, deaths: 2 },
		'03-08-2020': { confirmed: 114, recovered: 58, deaths: 3 },
		'03-09-2020': { confirmed: 115, recovered: 59, deaths: 3 },
	},
	Macau: {
		'01-22-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-23-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-24-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-25-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-26-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'01-27-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'01-28-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'01-29-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'01-30-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'01-31-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'02-03-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'02-04-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'02-05-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'02-06-2020': { confirmed: 10, recovered: 1, deaths: 0 },
		'02-07-2020': { confirmed: 10, recovered: 1, deaths: 0 },
		'02-08-2020': { confirmed: 10, recovered: 1, deaths: 0 },
		'02-09-2020': { confirmed: 10, recovered: 1, deaths: 0 },
		'02-10-2020': { confirmed: 10, recovered: 1, deaths: 0 },
		'02-11-2020': { confirmed: 10, recovered: 1, deaths: 0 },
		'02-12-2020': { confirmed: 10, recovered: 2, deaths: 0 },
		'02-13-2020': { confirmed: 10, recovered: 3, deaths: 0 },
		'02-14-2020': { confirmed: 10, recovered: 3, deaths: 0 },
		'02-15-2020': { confirmed: 10, recovered: 3, deaths: 0 },
		'02-16-2020': { confirmed: 10, recovered: 5, deaths: 0 },
		'02-17-2020': { confirmed: 10, recovered: 5, deaths: 0 },
		'02-18-2020': { confirmed: 10, recovered: 5, deaths: 0 },
		'02-19-2020': { confirmed: 10, recovered: 5, deaths: 0 },
		'02-20-2020': { confirmed: 10, recovered: 6, deaths: 0 },
		'02-21-2020': { confirmed: 10, recovered: 6, deaths: 0 },
		'02-22-2020': { confirmed: 10, recovered: 6, deaths: 0 },
		'02-23-2020': { confirmed: 10, recovered: 6, deaths: 0 },
		'02-24-2020': { confirmed: 10, recovered: 6, deaths: 0 },
		'02-25-2020': { confirmed: 10, recovered: 7, deaths: 0 },
		'02-26-2020': { confirmed: 10, recovered: 7, deaths: 0 },
		'02-27-2020': { confirmed: 10, recovered: 8, deaths: 0 },
		'02-28-2020': { confirmed: 10, recovered: 8, deaths: 0 },
		'02-29-2020': { confirmed: 10, recovered: 8, deaths: 0 },
		'03-01-2020': { confirmed: 10, recovered: 8, deaths: 0 },
		'03-02-2020': { confirmed: 10, recovered: 8, deaths: 0 },
		'03-03-2020': { confirmed: 10, recovered: 9, deaths: 0 },
		'03-04-2020': { confirmed: 10, recovered: 9, deaths: 0 },
		'03-05-2020': { confirmed: 10, recovered: 9, deaths: 0 },
		'03-06-2020': { confirmed: 10, recovered: 10, deaths: 0 },
		'03-07-2020': { confirmed: 10, recovered: 10, deaths: 0 },
		'03-08-2020': { confirmed: 10, recovered: 10, deaths: 0 },
		'03-09-2020': { confirmed: 10, recovered: 10, deaths: 0 },
	},
	Taiwan: {
		'01-22-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-23-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-24-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'01-25-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'01-26-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'01-27-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'01-28-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'01-29-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'01-30-2020': { confirmed: 9, recovered: 0, deaths: 0 },
		'01-31-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'02-03-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'02-04-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'02-05-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'02-06-2020': { confirmed: 16, recovered: 1, deaths: 0 },
		'02-07-2020': { confirmed: 16, recovered: 1, deaths: 0 },
		'02-08-2020': { confirmed: 17, recovered: 1, deaths: 0 },
		'02-09-2020': { confirmed: 18, recovered: 1, deaths: 0 },
		'02-10-2020': { confirmed: 18, recovered: 1, deaths: 0 },
		'02-11-2020': { confirmed: 18, recovered: 1, deaths: 0 },
		'02-12-2020': { confirmed: 18, recovered: 1, deaths: 0 },
		'02-13-2020': { confirmed: 18, recovered: 1, deaths: 0 },
		'02-14-2020': { confirmed: 18, recovered: 2, deaths: 0 },
		'02-15-2020': { confirmed: 18, recovered: 2, deaths: 0 },
		'02-16-2020': { confirmed: 20, recovered: 2, deaths: 1 },
		'02-17-2020': { confirmed: 22, recovered: 2, deaths: 1 },
		'02-18-2020': { confirmed: 22, recovered: 2, deaths: 1 },
		'02-19-2020': { confirmed: 23, recovered: 2, deaths: 1 },
		'02-20-2020': { confirmed: 24, recovered: 2, deaths: 1 },
		'02-21-2020': { confirmed: 26, recovered: 2, deaths: 1 },
		'02-22-2020': { confirmed: 26, recovered: 2, deaths: 1 },
		'02-23-2020': { confirmed: 28, recovered: 2, deaths: 1 },
		'02-24-2020': { confirmed: 30, recovered: 5, deaths: 1 },
		'02-25-2020': { confirmed: 31, recovered: 5, deaths: 1 },
		'02-26-2020': { confirmed: 32, recovered: 5, deaths: 1 },
		'02-27-2020': { confirmed: 32, recovered: 5, deaths: 1 },
		'02-28-2020': { confirmed: 34, recovered: 6, deaths: 1 },
		'02-29-2020': { confirmed: 39, recovered: 9, deaths: 1 },
		'03-01-2020': { confirmed: 40, recovered: 9, deaths: 1 },
		'03-02-2020': { confirmed: 41, recovered: 12, deaths: 1 },
		'03-03-2020': { confirmed: 42, recovered: 12, deaths: 1 },
		'03-04-2020': { confirmed: 42, recovered: 12, deaths: 1 },
		'03-05-2020': { confirmed: 44, recovered: 12, deaths: 1 },
		'03-06-2020': { confirmed: 45, recovered: 12, deaths: 1 },
		'03-07-2020': { confirmed: 45, recovered: 12, deaths: 1 },
		'03-08-2020': { confirmed: 45, recovered: 13, deaths: 1 },
		'03-09-2020': { confirmed: 45, recovered: 15, deaths: 1 },
	},
	US: {
		'01-22-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-23-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-24-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-25-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-26-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'01-27-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'01-28-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'01-29-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'01-30-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'01-31-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'02-03-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'02-04-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'02-05-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'02-06-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'02-07-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'02-08-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'02-09-2020': { confirmed: 12, recovered: 3, deaths: 0 },
		'02-10-2020': { confirmed: 12, recovered: 3, deaths: 0 },
		'02-11-2020': { confirmed: 13, recovered: 3, deaths: 0 },
		'02-12-2020': { confirmed: 13, recovered: 3, deaths: 0 },
		'02-13-2020': { confirmed: 15, recovered: 3, deaths: 0 },
		'02-14-2020': { confirmed: 15, recovered: 3, deaths: 0 },
		'02-15-2020': { confirmed: 15, recovered: 3, deaths: 0 },
		'02-16-2020': { confirmed: 15, recovered: 3, deaths: 0 },
		'02-17-2020': { confirmed: 15, recovered: 3, deaths: 0 },
		'02-18-2020': { confirmed: 15, recovered: 3, deaths: 0 },
		'02-19-2020': { confirmed: 15, recovered: 3, deaths: 0 },
		'02-20-2020': { confirmed: 15, recovered: 3, deaths: 0 },
		'02-21-2020': { confirmed: 35, recovered: 5, deaths: 0 },
		'02-22-2020': { confirmed: 35, recovered: 5, deaths: 0 },
		'02-23-2020': { confirmed: 35, recovered: 5, deaths: 0 },
		'02-24-2020': { confirmed: 53, recovered: 5, deaths: 0 },
		'02-25-2020': { confirmed: 53, recovered: 6, deaths: 0 },
		'02-26-2020': { confirmed: 59, recovered: 6, deaths: 0 },
		'02-27-2020': { confirmed: 60, recovered: 6, deaths: 0 },
		'02-28-2020': { confirmed: 62, recovered: 7, deaths: 0 },
		'02-29-2020': { confirmed: 70, recovered: 7, deaths: 1 },
		'03-01-2020': { confirmed: 76, recovered: 7, deaths: 1 },
		'03-02-2020': { confirmed: 101, recovered: 7, deaths: 6 },
		'03-03-2020': { confirmed: 122, recovered: 8, deaths: 7 },
		'03-04-2020': { confirmed: 153, recovered: 8, deaths: 11 },
		'03-05-2020': { confirmed: 221, recovered: 8, deaths: 12 },
		'03-06-2020': { confirmed: 278, recovered: 8, deaths: 14 },
		'03-07-2020': { confirmed: 417, recovered: 8, deaths: 17 },
		'03-08-2020': { confirmed: 537, recovered: 8, deaths: 21 },
		'03-09-2020': { confirmed: 605, recovered: 8, deaths: 22 },
		'03-10-2020': { confirmed: 959, recovered: 8, deaths: 28 },
		'03-11-2020': { confirmed: 1281, recovered: 8, deaths: 36 },
		'03-12-2020': { confirmed: 1663, recovered: 12, deaths: 40 },
		'03-13-2020': { confirmed: 2179, recovered: 12, deaths: 47 },
		'03-14-2020': { confirmed: 2726, recovered: 12, deaths: 54 },
		'03-15-2020': { confirmed: 3499, recovered: 12, deaths: 63 },
		'03-16-2020': { confirmed: 4632, recovered: 17, deaths: 85 },
		'03-17-2020': { confirmed: 6421, recovered: 17, deaths: 108 },
		'03-18-2020': { confirmed: 7786, recovered: 106, deaths: 118 },
	},
	Japan: {
		'01-22-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-23-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-24-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-25-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-26-2020': { confirmed: 4, recovered: 1, deaths: 0 },
		'01-27-2020': { confirmed: 4, recovered: 1, deaths: 0 },
		'01-28-2020': { confirmed: 7, recovered: 1, deaths: 0 },
		'01-29-2020': { confirmed: 7, recovered: 1, deaths: 0 },
		'01-30-2020': { confirmed: 11, recovered: 1, deaths: 0 },
		'01-31-2020': { confirmed: 15, recovered: 1, deaths: 0 },
		'02-01-2020': { confirmed: 20, recovered: 1, deaths: 0 },
		'02-02-2020': { confirmed: 20, recovered: 1, deaths: 0 },
		'02-03-2020': { confirmed: 20, recovered: 1, deaths: 0 },
		'02-04-2020': { confirmed: 22, recovered: 1, deaths: 0 },
		'02-05-2020': { confirmed: 22, recovered: 1, deaths: 0 },
		'02-06-2020': { confirmed: 45, recovered: 1, deaths: 0 },
		'02-07-2020': { confirmed: 25, recovered: 1, deaths: 0 },
		'02-08-2020': { confirmed: 25, recovered: 1, deaths: 0 },
		'02-09-2020': { confirmed: 26, recovered: 1, deaths: 0 },
		'02-10-2020': { confirmed: 26, recovered: 4, deaths: 0 },
		'02-11-2020': { confirmed: 26, recovered: 9, deaths: 0 },
		'02-12-2020': { confirmed: 28, recovered: 9, deaths: 0 },
		'02-13-2020': { confirmed: 28, recovered: 9, deaths: 1 },
		'02-14-2020': { confirmed: 29, recovered: 9, deaths: 1 },
		'02-15-2020': { confirmed: 43, recovered: 12, deaths: 1 },
		'02-16-2020': { confirmed: 59, recovered: 12, deaths: 1 },
		'02-17-2020': { confirmed: 66, recovered: 12, deaths: 1 },
		'02-18-2020': { confirmed: 74, recovered: 13, deaths: 1 },
		'02-19-2020': { confirmed: 84, recovered: 18, deaths: 1 },
		'02-20-2020': { confirmed: 94, recovered: 18, deaths: 1 },
		'02-21-2020': { confirmed: 105, recovered: 22, deaths: 1 },
		'02-22-2020': { confirmed: 122, recovered: 22, deaths: 1 },
		'02-23-2020': { confirmed: 147, recovered: 22, deaths: 1 },
		'02-24-2020': { confirmed: 159, recovered: 22, deaths: 1 },
		'02-25-2020': { confirmed: 170, recovered: 22, deaths: 1 },
		'02-26-2020': { confirmed: 189, recovered: 22, deaths: 2 },
		'02-27-2020': { confirmed: 214, recovered: 22, deaths: 4 },
		'02-28-2020': { confirmed: 228, recovered: 22, deaths: 4 },
		'02-29-2020': { confirmed: 241, recovered: 32, deaths: 5 },
		'03-01-2020': { confirmed: 256, recovered: 32, deaths: 6 },
		'03-02-2020': { confirmed: 274, recovered: 32, deaths: 6 },
		'03-03-2020': { confirmed: 293, recovered: 43, deaths: 6 },
		'03-04-2020': { confirmed: 331, recovered: 43, deaths: 6 },
		'03-05-2020': { confirmed: 360, recovered: 43, deaths: 6 },
		'03-06-2020': { confirmed: 420, recovered: 46, deaths: 6 },
		'03-07-2020': { confirmed: 461, recovered: 76, deaths: 6 },
		'03-08-2020': { confirmed: 502, recovered: 76, deaths: 6 },
		'03-09-2020': { confirmed: 511, recovered: 76, deaths: 17 },
		'03-10-2020': { confirmed: 581, recovered: 101, deaths: 10 },
		'03-11-2020': { confirmed: 639, recovered: 118, deaths: 15 },
		'03-12-2020': { confirmed: 639, recovered: 118, deaths: 16 },
		'03-13-2020': { confirmed: 701, recovered: 118, deaths: 19 },
		'03-14-2020': { confirmed: 773, recovered: 118, deaths: 22 },
		'03-15-2020': { confirmed: 839, recovered: 118, deaths: 22 },
		'03-16-2020': { confirmed: 825, recovered: 144, deaths: 27 },
		'03-17-2020': { confirmed: 878, recovered: 144, deaths: 29 },
		'03-18-2020': { confirmed: 889, recovered: 144, deaths: 29 },
	},
	Thailand: {
		'01-22-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-23-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'01-24-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'01-25-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'01-26-2020': { confirmed: 8, recovered: 2, deaths: 0 },
		'01-27-2020': { confirmed: 8, recovered: 2, deaths: 0 },
		'01-28-2020': { confirmed: 14, recovered: 5, deaths: 0 },
		'01-29-2020': { confirmed: 14, recovered: 5, deaths: 0 },
		'01-30-2020': { confirmed: 14, recovered: 5, deaths: 0 },
		'01-31-2020': { confirmed: 19, recovered: 5, deaths: 0 },
		'02-01-2020': { confirmed: 19, recovered: 5, deaths: 0 },
		'02-02-2020': { confirmed: 19, recovered: 5, deaths: 0 },
		'02-03-2020': { confirmed: 19, recovered: 5, deaths: 0 },
		'02-04-2020': { confirmed: 25, recovered: 5, deaths: 0 },
		'02-05-2020': { confirmed: 25, recovered: 5, deaths: 0 },
		'02-06-2020': { confirmed: 25, recovered: 5, deaths: 0 },
		'02-07-2020': { confirmed: 25, recovered: 5, deaths: 0 },
		'02-08-2020': { confirmed: 32, recovered: 10, deaths: 0 },
		'02-09-2020': { confirmed: 32, recovered: 10, deaths: 0 },
		'02-10-2020': { confirmed: 32, recovered: 10, deaths: 0 },
		'02-11-2020': { confirmed: 33, recovered: 10, deaths: 0 },
		'02-12-2020': { confirmed: 33, recovered: 10, deaths: 0 },
		'02-13-2020': { confirmed: 33, recovered: 12, deaths: 0 },
		'02-14-2020': { confirmed: 33, recovered: 12, deaths: 0 },
		'02-15-2020': { confirmed: 33, recovered: 12, deaths: 0 },
		'02-16-2020': { confirmed: 34, recovered: 14, deaths: 0 },
		'02-17-2020': { confirmed: 35, recovered: 15, deaths: 0 },
		'02-18-2020': { confirmed: 35, recovered: 15, deaths: 0 },
		'02-19-2020': { confirmed: 35, recovered: 15, deaths: 0 },
		'02-20-2020': { confirmed: 35, recovered: 15, deaths: 0 },
		'02-21-2020': { confirmed: 35, recovered: 17, deaths: 0 },
		'02-22-2020': { confirmed: 35, recovered: 17, deaths: 0 },
		'02-23-2020': { confirmed: 35, recovered: 21, deaths: 0 },
		'02-24-2020': { confirmed: 35, recovered: 21, deaths: 0 },
		'02-25-2020': { confirmed: 37, recovered: 22, deaths: 0 },
		'02-26-2020': { confirmed: 40, recovered: 22, deaths: 0 },
		'02-27-2020': { confirmed: 40, recovered: 22, deaths: 0 },
		'02-28-2020': { confirmed: 41, recovered: 28, deaths: 0 },
		'02-29-2020': { confirmed: 42, recovered: 28, deaths: 0 },
		'03-01-2020': { confirmed: 42, recovered: 28, deaths: 1 },
		'03-02-2020': { confirmed: 43, recovered: 31, deaths: 1 },
		'03-03-2020': { confirmed: 43, recovered: 31, deaths: 1 },
		'03-04-2020': { confirmed: 43, recovered: 31, deaths: 1 },
		'03-05-2020': { confirmed: 47, recovered: 31, deaths: 1 },
		'03-06-2020': { confirmed: 48, recovered: 31, deaths: 1 },
		'03-07-2020': { confirmed: 50, recovered: 31, deaths: 1 },
		'03-08-2020': { confirmed: 50, recovered: 31, deaths: 1 },
		'03-09-2020': { confirmed: 50, recovered: 31, deaths: 1 },
		'03-10-2020': { confirmed: 53, recovered: 33, deaths: 1 },
		'03-11-2020': { confirmed: 59, recovered: 34, deaths: 1 },
		'03-12-2020': { confirmed: 70, recovered: 34, deaths: 1 },
		'03-13-2020': { confirmed: 75, recovered: 35, deaths: 1 },
		'03-14-2020': { confirmed: 82, recovered: 35, deaths: 1 },
		'03-15-2020': { confirmed: 114, recovered: 35, deaths: 1 },
		'03-16-2020': { confirmed: 147, recovered: 35, deaths: 1 },
		'03-17-2020': { confirmed: 177, recovered: 41, deaths: 1 },
		'03-18-2020': { confirmed: 212, recovered: 42, deaths: 1 },
	},
	'South Korea': {
		'01-22-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-23-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-24-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-25-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-26-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'01-27-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'01-28-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'01-29-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'01-30-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'01-31-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 15, recovered: 0, deaths: 0 },
		'02-03-2020': { confirmed: 15, recovered: 0, deaths: 0 },
		'02-04-2020': { confirmed: 16, recovered: 0, deaths: 0 },
		'02-05-2020': { confirmed: 19, recovered: 0, deaths: 0 },
		'02-06-2020': { confirmed: 23, recovered: 0, deaths: 0 },
		'02-07-2020': { confirmed: 24, recovered: 1, deaths: 0 },
		'02-08-2020': { confirmed: 24, recovered: 1, deaths: 0 },
		'02-09-2020': { confirmed: 25, recovered: 3, deaths: 0 },
		'02-10-2020': { confirmed: 27, recovered: 3, deaths: 0 },
		'02-11-2020': { confirmed: 28, recovered: 3, deaths: 0 },
		'02-12-2020': { confirmed: 28, recovered: 7, deaths: 0 },
		'02-13-2020': { confirmed: 28, recovered: 7, deaths: 0 },
		'02-14-2020': { confirmed: 28, recovered: 7, deaths: 0 },
		'02-15-2020': { confirmed: 28, recovered: 9, deaths: 0 },
		'02-16-2020': { confirmed: 29, recovered: 9, deaths: 0 },
		'02-17-2020': { confirmed: 30, recovered: 10, deaths: 0 },
		'02-18-2020': { confirmed: 31, recovered: 12, deaths: 0 },
		'02-19-2020': { confirmed: 31, recovered: 12, deaths: 0 },
		'02-20-2020': { confirmed: 104, recovered: 16, deaths: 1 },
		'02-21-2020': { confirmed: 204, recovered: 16, deaths: 2 },
		'02-22-2020': { confirmed: 433, recovered: 16, deaths: 2 },
		'02-23-2020': { confirmed: 602, recovered: 18, deaths: 6 },
		'02-24-2020': { confirmed: 833, recovered: 18, deaths: 8 },
		'02-25-2020': { confirmed: 977, recovered: 22, deaths: 10 },
		'02-26-2020': { confirmed: 1261, recovered: 22, deaths: 12 },
		'02-27-2020': { confirmed: 1766, recovered: 22, deaths: 13 },
		'02-28-2020': { confirmed: 2337, recovered: 22, deaths: 13 },
		'02-29-2020': { confirmed: 3150, recovered: 27, deaths: 16 },
		'03-01-2020': { confirmed: 3736, recovered: 30, deaths: 17 },
		'03-02-2020': { confirmed: 4335, recovered: 30, deaths: 28 },
		'03-03-2020': { confirmed: 5186, recovered: 30, deaths: 28 },
		'03-04-2020': { confirmed: 5621, recovered: 41, deaths: 35 },
		'03-05-2020': { confirmed: 6088, recovered: 41, deaths: 35 },
		'03-06-2020': { confirmed: 6593, recovered: 135, deaths: 42 },
		'03-07-2020': { confirmed: 7041, recovered: 135, deaths: 44 },
		'03-08-2020': { confirmed: 7314, recovered: 118, deaths: 50 },
		'03-09-2020': { confirmed: 7478, recovered: 118, deaths: 53 },
	},
	Singapore: {
		'01-23-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-24-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'01-25-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'01-26-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'01-27-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'01-28-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'01-29-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'01-30-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'01-31-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 16, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 18, recovered: 0, deaths: 0 },
		'02-03-2020': { confirmed: 18, recovered: 0, deaths: 0 },
		'02-04-2020': { confirmed: 24, recovered: 0, deaths: 0 },
		'02-05-2020': { confirmed: 28, recovered: 0, deaths: 0 },
		'02-06-2020': { confirmed: 28, recovered: 0, deaths: 0 },
		'02-07-2020': { confirmed: 30, recovered: 0, deaths: 0 },
		'02-08-2020': { confirmed: 33, recovered: 2, deaths: 0 },
		'02-09-2020': { confirmed: 40, recovered: 2, deaths: 0 },
		'02-10-2020': { confirmed: 45, recovered: 2, deaths: 0 },
		'02-11-2020': { confirmed: 47, recovered: 9, deaths: 0 },
		'02-12-2020': { confirmed: 50, recovered: 15, deaths: 0 },
		'02-13-2020': { confirmed: 58, recovered: 15, deaths: 0 },
		'02-14-2020': { confirmed: 67, recovered: 17, deaths: 0 },
		'02-15-2020': { confirmed: 72, recovered: 18, deaths: 0 },
		'02-16-2020': { confirmed: 75, recovered: 18, deaths: 0 },
		'02-17-2020': { confirmed: 77, recovered: 24, deaths: 0 },
		'02-18-2020': { confirmed: 81, recovered: 29, deaths: 0 },
		'02-19-2020': { confirmed: 84, recovered: 34, deaths: 0 },
		'02-20-2020': { confirmed: 84, recovered: 34, deaths: 0 },
		'02-21-2020': { confirmed: 85, recovered: 37, deaths: 0 },
		'02-22-2020': { confirmed: 85, recovered: 37, deaths: 0 },
		'02-23-2020': { confirmed: 89, recovered: 51, deaths: 0 },
		'02-24-2020': { confirmed: 89, recovered: 51, deaths: 0 },
		'02-25-2020': { confirmed: 91, recovered: 53, deaths: 0 },
		'02-26-2020': { confirmed: 93, recovered: 62, deaths: 0 },
		'02-27-2020': { confirmed: 93, recovered: 62, deaths: 0 },
		'02-28-2020': { confirmed: 93, recovered: 62, deaths: 0 },
		'02-29-2020': { confirmed: 102, recovered: 72, deaths: 0 },
		'03-01-2020': { confirmed: 106, recovered: 72, deaths: 0 },
		'03-02-2020': { confirmed: 108, recovered: 78, deaths: 0 },
		'03-03-2020': { confirmed: 110, recovered: 78, deaths: 0 },
		'03-04-2020': { confirmed: 110, recovered: 78, deaths: 0 },
		'03-05-2020': { confirmed: 117, recovered: 78, deaths: 0 },
		'03-06-2020': { confirmed: 130, recovered: 78, deaths: 0 },
		'03-07-2020': { confirmed: 138, recovered: 78, deaths: 0 },
		'03-08-2020': { confirmed: 150, recovered: 78, deaths: 0 },
		'03-09-2020': { confirmed: 150, recovered: 78, deaths: 0 },
		'03-10-2020': { confirmed: 160, recovered: 78, deaths: 0 },
		'03-11-2020': { confirmed: 178, recovered: 96, deaths: 0 },
		'03-12-2020': { confirmed: 178, recovered: 96, deaths: 0 },
		'03-13-2020': { confirmed: 200, recovered: 97, deaths: 0 },
		'03-14-2020': { confirmed: 212, recovered: 105, deaths: 0 },
		'03-15-2020': { confirmed: 226, recovered: 105, deaths: 0 },
		'03-16-2020': { confirmed: 243, recovered: 109, deaths: 0 },
		'03-17-2020': { confirmed: 266, recovered: 114, deaths: 0 },
		'03-18-2020': { confirmed: 313, recovered: 114, deaths: 0 },
	},
	Philippines: {
		'01-23-2020': { confirmed: 0, recovered: 0, deaths: 0 },
		'01-30-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-31-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 2, recovered: 0, deaths: 1 },
		'02-03-2020': { confirmed: 2, recovered: 0, deaths: 1 },
		'02-04-2020': { confirmed: 2, recovered: 0, deaths: 1 },
		'02-05-2020': { confirmed: 2, recovered: 0, deaths: 1 },
		'02-06-2020': { confirmed: 2, recovered: 0, deaths: 1 },
		'02-07-2020': { confirmed: 3, recovered: 0, deaths: 1 },
		'02-08-2020': { confirmed: 3, recovered: 0, deaths: 1 },
		'02-09-2020': { confirmed: 3, recovered: 0, deaths: 1 },
		'02-10-2020': { confirmed: 3, recovered: 0, deaths: 1 },
		'02-11-2020': { confirmed: 3, recovered: 0, deaths: 1 },
		'02-12-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'02-13-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'02-14-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'02-15-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'02-16-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'02-17-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'02-18-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'02-19-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'02-20-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'02-21-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'02-22-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'02-23-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'02-24-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'02-25-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'02-26-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'02-27-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'02-28-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'02-29-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'03-01-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'03-02-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'03-03-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'03-04-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'03-05-2020': { confirmed: 3, recovered: 1, deaths: 1 },
		'03-06-2020': { confirmed: 5, recovered: 1, deaths: 1 },
		'03-07-2020': { confirmed: 6, recovered: 1, deaths: 1 },
		'03-08-2020': { confirmed: 10, recovered: 1, deaths: 1 },
		'03-09-2020': { confirmed: 20, recovered: 1, deaths: 1 },
		'03-10-2020': { confirmed: 33, recovered: 2, deaths: 1 },
		'03-11-2020': { confirmed: 49, recovered: 2, deaths: 1 },
		'03-12-2020': { confirmed: 52, recovered: 2, deaths: 2 },
		'03-13-2020': { confirmed: 64, recovered: 2, deaths: 5 },
		'03-14-2020': { confirmed: 111, recovered: 2, deaths: 8 },
		'03-15-2020': { confirmed: 140, recovered: 2, deaths: 11 },
		'03-16-2020': { confirmed: 142, recovered: 2, deaths: 12 },
		'03-17-2020': { confirmed: 187, recovered: 5, deaths: 12 },
		'03-18-2020': { confirmed: 202, recovered: 5, deaths: 19 },
	},
	Malaysia: {
		'01-23-2020': { confirmed: 0, recovered: 0, deaths: 0 },
		'01-25-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'01-26-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'01-27-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'01-28-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'01-29-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'01-30-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'01-31-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'02-03-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'02-04-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'02-05-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'02-06-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'02-07-2020': { confirmed: 12, recovered: 1, deaths: 0 },
		'02-08-2020': { confirmed: 16, recovered: 1, deaths: 0 },
		'02-09-2020': { confirmed: 16, recovered: 1, deaths: 0 },
		'02-10-2020': { confirmed: 18, recovered: 1, deaths: 0 },
		'02-11-2020': { confirmed: 18, recovered: 3, deaths: 0 },
		'02-12-2020': { confirmed: 18, recovered: 3, deaths: 0 },
		'02-13-2020': { confirmed: 19, recovered: 3, deaths: 0 },
		'02-14-2020': { confirmed: 19, recovered: 3, deaths: 0 },
		'02-15-2020': { confirmed: 22, recovered: 7, deaths: 0 },
		'02-16-2020': { confirmed: 22, recovered: 7, deaths: 0 },
		'02-17-2020': { confirmed: 22, recovered: 7, deaths: 0 },
		'02-18-2020': { confirmed: 22, recovered: 13, deaths: 0 },
		'02-19-2020': { confirmed: 22, recovered: 15, deaths: 0 },
		'02-20-2020': { confirmed: 22, recovered: 15, deaths: 0 },
		'02-21-2020': { confirmed: 22, recovered: 15, deaths: 0 },
		'02-22-2020': { confirmed: 22, recovered: 15, deaths: 0 },
		'02-23-2020': { confirmed: 22, recovered: 15, deaths: 0 },
		'02-24-2020': { confirmed: 22, recovered: 18, deaths: 0 },
		'02-25-2020': { confirmed: 22, recovered: 18, deaths: 0 },
		'02-26-2020': { confirmed: 22, recovered: 18, deaths: 0 },
		'02-27-2020': { confirmed: 23, recovered: 18, deaths: 0 },
		'02-28-2020': { confirmed: 23, recovered: 18, deaths: 0 },
		'02-29-2020': { confirmed: 25, recovered: 18, deaths: 0 },
		'03-01-2020': { confirmed: 29, recovered: 18, deaths: 0 },
		'03-02-2020': { confirmed: 29, recovered: 18, deaths: 0 },
		'03-03-2020': { confirmed: 36, recovered: 22, deaths: 0 },
		'03-04-2020': { confirmed: 50, recovered: 22, deaths: 0 },
		'03-05-2020': { confirmed: 50, recovered: 22, deaths: 0 },
		'03-06-2020': { confirmed: 83, recovered: 22, deaths: 0 },
		'03-07-2020': { confirmed: 93, recovered: 23, deaths: 0 },
		'03-08-2020': { confirmed: 99, recovered: 24, deaths: 0 },
		'03-09-2020': { confirmed: 117, recovered: 24, deaths: 0 },
		'03-10-2020': { confirmed: 129, recovered: 24, deaths: 0 },
		'03-11-2020': { confirmed: 149, recovered: 26, deaths: 0 },
		'03-12-2020': { confirmed: 149, recovered: 26, deaths: 0 },
		'03-13-2020': { confirmed: 197, recovered: 26, deaths: 0 },
		'03-14-2020': { confirmed: 238, recovered: 35, deaths: 0 },
		'03-15-2020': { confirmed: 428, recovered: 42, deaths: 0 },
		'03-16-2020': { confirmed: 566, recovered: 42, deaths: 0 },
		'03-17-2020': { confirmed: 673, recovered: 49, deaths: 2 },
		'03-18-2020': { confirmed: 790, recovered: 60, deaths: 2 },
	},
	Vietnam: {
		'01-23-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-24-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-25-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-26-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-27-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-28-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-29-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-30-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-31-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 6, recovered: 1, deaths: 0 },
		'02-02-2020': { confirmed: 6, recovered: 1, deaths: 0 },
		'02-03-2020': { confirmed: 8, recovered: 1, deaths: 0 },
		'02-04-2020': { confirmed: 8, recovered: 1, deaths: 0 },
		'02-05-2020': { confirmed: 8, recovered: 1, deaths: 0 },
		'02-06-2020': { confirmed: 10, recovered: 1, deaths: 0 },
		'02-07-2020': { confirmed: 10, recovered: 1, deaths: 0 },
		'02-08-2020': { confirmed: 13, recovered: 1, deaths: 0 },
		'02-09-2020': { confirmed: 13, recovered: 1, deaths: 0 },
		'02-10-2020': { confirmed: 14, recovered: 1, deaths: 0 },
		'02-11-2020': { confirmed: 15, recovered: 6, deaths: 0 },
		'02-12-2020': { confirmed: 15, recovered: 6, deaths: 0 },
		'02-13-2020': { confirmed: 16, recovered: 7, deaths: 0 },
		'02-14-2020': { confirmed: 16, recovered: 7, deaths: 0 },
		'02-15-2020': { confirmed: 16, recovered: 7, deaths: 0 },
		'02-16-2020': { confirmed: 16, recovered: 7, deaths: 0 },
		'02-17-2020': { confirmed: 16, recovered: 7, deaths: 0 },
		'02-18-2020': { confirmed: 16, recovered: 7, deaths: 0 },
		'02-19-2020': { confirmed: 16, recovered: 7, deaths: 0 },
		'02-20-2020': { confirmed: 16, recovered: 7, deaths: 0 },
		'02-21-2020': { confirmed: 16, recovered: 14, deaths: 0 },
		'02-22-2020': { confirmed: 16, recovered: 14, deaths: 0 },
		'02-23-2020': { confirmed: 16, recovered: 14, deaths: 0 },
		'02-24-2020': { confirmed: 16, recovered: 14, deaths: 0 },
		'02-25-2020': { confirmed: 16, recovered: 16, deaths: 0 },
		'02-26-2020': { confirmed: 16, recovered: 16, deaths: 0 },
		'02-27-2020': { confirmed: 16, recovered: 16, deaths: 0 },
		'02-28-2020': { confirmed: 16, recovered: 16, deaths: 0 },
		'02-29-2020': { confirmed: 16, recovered: 16, deaths: 0 },
		'03-01-2020': { confirmed: 16, recovered: 16, deaths: 0 },
		'03-02-2020': { confirmed: 16, recovered: 16, deaths: 0 },
		'03-03-2020': { confirmed: 16, recovered: 16, deaths: 0 },
		'03-04-2020': { confirmed: 16, recovered: 16, deaths: 0 },
		'03-05-2020': { confirmed: 16, recovered: 16, deaths: 0 },
		'03-06-2020': { confirmed: 16, recovered: 16, deaths: 0 },
		'03-07-2020': { confirmed: 18, recovered: 16, deaths: 0 },
		'03-08-2020': { confirmed: 30, recovered: 16, deaths: 0 },
		'03-09-2020': { confirmed: 30, recovered: 16, deaths: 0 },
		'03-11-2020': { confirmed: 38, recovered: 16, deaths: 0 },
		'03-12-2020': { confirmed: 39, recovered: 16, deaths: 0 },
		'03-13-2020': { confirmed: 47, recovered: 16, deaths: 0 },
		'03-14-2020': { confirmed: 53, recovered: 16, deaths: 0 },
		'03-15-2020': { confirmed: 56, recovered: 16, deaths: 0 },
		'03-16-2020': { confirmed: 61, recovered: 16, deaths: 0 },
		'03-17-2020': { confirmed: 66, recovered: 16, deaths: 0 },
		'03-18-2020': { confirmed: 75, recovered: 16, deaths: 0 },
	},
	Australia: {
		'01-23-2020': { confirmed: 0, recovered: 0, deaths: 0 },
		'01-25-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'01-26-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'01-27-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'01-28-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'01-29-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'01-30-2020': { confirmed: 9, recovered: 2, deaths: 0 },
		'01-31-2020': { confirmed: 9, recovered: 2, deaths: 0 },
		'02-01-2020': { confirmed: 12, recovered: 2, deaths: 0 },
		'02-02-2020': { confirmed: 12, recovered: 2, deaths: 0 },
		'02-03-2020': { confirmed: 12, recovered: 2, deaths: 0 },
		'02-04-2020': { confirmed: 13, recovered: 2, deaths: 0 },
		'02-05-2020': { confirmed: 13, recovered: 2, deaths: 0 },
		'02-06-2020': { confirmed: 14, recovered: 2, deaths: 0 },
		'02-07-2020': { confirmed: 15, recovered: 2, deaths: 0 },
		'02-08-2020': { confirmed: 15, recovered: 2, deaths: 0 },
		'02-09-2020': { confirmed: 15, recovered: 2, deaths: 0 },
		'02-10-2020': { confirmed: 15, recovered: 2, deaths: 0 },
		'02-11-2020': { confirmed: 15, recovered: 2, deaths: 0 },
		'02-12-2020': { confirmed: 15, recovered: 2, deaths: 0 },
		'02-13-2020': { confirmed: 15, recovered: 8, deaths: 0 },
		'02-14-2020': { confirmed: 15, recovered: 8, deaths: 0 },
		'02-15-2020': { confirmed: 15, recovered: 8, deaths: 0 },
		'02-16-2020': { confirmed: 15, recovered: 8, deaths: 0 },
		'02-17-2020': { confirmed: 15, recovered: 10, deaths: 0 },
		'02-18-2020': { confirmed: 15, recovered: 10, deaths: 0 },
		'02-19-2020': { confirmed: 15, recovered: 10, deaths: 0 },
		'02-20-2020': { confirmed: 15, recovered: 10, deaths: 0 },
		'02-21-2020': { confirmed: 19, recovered: 11, deaths: 0 },
		'02-22-2020': { confirmed: 22, recovered: 11, deaths: 0 },
		'02-23-2020': { confirmed: 22, recovered: 11, deaths: 0 },
		'02-24-2020': { confirmed: 22, recovered: 11, deaths: 0 },
		'02-25-2020': { confirmed: 22, recovered: 11, deaths: 0 },
		'02-26-2020': { confirmed: 22, recovered: 11, deaths: 0 },
		'02-27-2020': { confirmed: 23, recovered: 11, deaths: 0 },
		'02-28-2020': { confirmed: 23, recovered: 11, deaths: 0 },
		'02-29-2020': { confirmed: 25, recovered: 11, deaths: 0 },
		'03-01-2020': { confirmed: 27, recovered: 11, deaths: 1 },
		'03-02-2020': { confirmed: 30, recovered: 11, deaths: 1 },
		'03-03-2020': { confirmed: 39, recovered: 11, deaths: 1 },
		'03-04-2020': { confirmed: 52, recovered: 11, deaths: 2 },
		'03-05-2020': { confirmed: 55, recovered: 21, deaths: 2 },
		'03-06-2020': { confirmed: 60, recovered: 21, deaths: 2 },
		'03-07-2020': { confirmed: 63, recovered: 21, deaths: 2 },
		'03-08-2020': { confirmed: 76, recovered: 21, deaths: 4 },
		'03-09-2020': { confirmed: 91, recovered: 21, deaths: 4 },
		'03-10-2020': { confirmed: 107, recovered: 21, deaths: 3 },
		'03-11-2020': { confirmed: 128, recovered: 21, deaths: 3 },
		'03-12-2020': { confirmed: 128, recovered: 21, deaths: 3 },
		'03-13-2020': { confirmed: 200, recovered: 23, deaths: 3 },
		'03-14-2020': { confirmed: 250, recovered: 23, deaths: 3 },
		'03-15-2020': { confirmed: 297, recovered: 23, deaths: 3 },
		'03-16-2020': { confirmed: 377, recovered: 23, deaths: 3 },
		'03-17-2020': { confirmed: 452, recovered: 23, deaths: 5 },
		'03-18-2020': { confirmed: 568, recovered: 23, deaths: 6 },
	},
	Mexico: {
		'01-23-2020': { confirmed: 0, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 5, recovered: 1, deaths: 0 },
		'03-04-2020': { confirmed: 5, recovered: 1, deaths: 0 },
		'03-05-2020': { confirmed: 5, recovered: 1, deaths: 0 },
		'03-06-2020': { confirmed: 6, recovered: 1, deaths: 0 },
		'03-07-2020': { confirmed: 6, recovered: 1, deaths: 0 },
		'03-08-2020': { confirmed: 7, recovered: 1, deaths: 0 },
		'03-09-2020': { confirmed: 7, recovered: 1, deaths: 0 },
		'03-10-2020': { confirmed: 7, recovered: 4, deaths: 0 },
		'03-11-2020': { confirmed: 8, recovered: 4, deaths: 0 },
		'03-12-2020': { confirmed: 12, recovered: 4, deaths: 0 },
		'03-13-2020': { confirmed: 12, recovered: 4, deaths: 0 },
		'03-14-2020': { confirmed: 26, recovered: 4, deaths: 0 },
		'03-15-2020': { confirmed: 41, recovered: 4, deaths: 0 },
		'03-16-2020': { confirmed: 53, recovered: 4, deaths: 0 },
		'03-17-2020': { confirmed: 82, recovered: 4, deaths: 0 },
		'03-18-2020': { confirmed: 93, recovered: 4, deaths: 0 },
	},
	Brazil: {
		'01-23-2020': { confirmed: 0, recovered: 0, deaths: 0 },
		'02-26-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-27-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 20, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 25, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 31, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 38, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 52, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 151, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 151, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 162, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 200, recovered: 1, deaths: 0 },
		'03-17-2020': { confirmed: 321, recovered: 2, deaths: 1 },
		'03-18-2020': { confirmed: 372, recovered: 2, deaths: 3 },
	},
	Colombia: {
		'01-23-2020': { confirmed: 0, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 9, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 9, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 22, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 34, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 54, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 65, recovered: 1, deaths: 0 },
		'03-18-2020': { confirmed: 93, recovered: 1, deaths: 0 },
	},
	France: {
		'01-24-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-25-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'01-26-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'01-27-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'01-28-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'01-29-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'01-30-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'01-31-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'02-03-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'02-04-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'02-05-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'02-06-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'02-07-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'02-08-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'02-09-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'02-10-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'02-11-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'02-12-2020': { confirmed: 11, recovered: 2, deaths: 0 },
		'02-13-2020': { confirmed: 11, recovered: 2, deaths: 0 },
		'02-14-2020': { confirmed: 11, recovered: 2, deaths: 0 },
		'02-15-2020': { confirmed: 12, recovered: 4, deaths: 1 },
		'02-16-2020': { confirmed: 12, recovered: 4, deaths: 1 },
		'02-17-2020': { confirmed: 12, recovered: 4, deaths: 1 },
		'02-18-2020': { confirmed: 12, recovered: 4, deaths: 1 },
		'02-19-2020': { confirmed: 12, recovered: 4, deaths: 1 },
		'02-20-2020': { confirmed: 12, recovered: 4, deaths: 1 },
		'02-21-2020': { confirmed: 12, recovered: 4, deaths: 1 },
		'02-22-2020': { confirmed: 12, recovered: 4, deaths: 1 },
		'02-23-2020': { confirmed: 12, recovered: 4, deaths: 1 },
		'02-24-2020': { confirmed: 12, recovered: 4, deaths: 1 },
		'02-25-2020': { confirmed: 14, recovered: 11, deaths: 1 },
		'02-26-2020': { confirmed: 18, recovered: 11, deaths: 2 },
		'02-27-2020': { confirmed: 38, recovered: 11, deaths: 2 },
		'02-28-2020': { confirmed: 57, recovered: 11, deaths: 2 },
		'02-29-2020': { confirmed: 100, recovered: 12, deaths: 2 },
		'03-01-2020': { confirmed: 130, recovered: 12, deaths: 2 },
		'03-02-2020': { confirmed: 191, recovered: 12, deaths: 3 },
		'03-03-2020': { confirmed: 204, recovered: 12, deaths: 4 },
		'03-04-2020': { confirmed: 285, recovered: 12, deaths: 4 },
		'03-05-2020': { confirmed: 377, recovered: 12, deaths: 6 },
		'03-06-2020': { confirmed: 653, recovered: 12, deaths: 9 },
		'03-07-2020': { confirmed: 949, recovered: 12, deaths: 11 },
		'03-08-2020': { confirmed: 1126, recovered: 12, deaths: 19 },
		'03-09-2020': { confirmed: 1209, recovered: 12, deaths: 19 },
		'03-10-2020': { confirmed: 1784, recovered: 12, deaths: 33 },
		'03-11-2020': { confirmed: 2284, recovered: 12, deaths: 48 },
		'03-12-2020': { confirmed: 2284, recovered: 12, deaths: 48 },
		'03-13-2020': { confirmed: 3667, recovered: 12, deaths: 79 },
		'03-14-2020': { confirmed: 4480, recovered: 12, deaths: 91 },
		'03-15-2020': { confirmed: 4513, recovered: 12, deaths: 91 },
		'03-16-2020': { confirmed: 6650, recovered: 12, deaths: 148 },
		'03-17-2020': { confirmed: 7683, recovered: 12, deaths: 148 },
		'03-18-2020': { confirmed: 9052, recovered: 12, deaths: 148 },
	},
	Nepal: {
		'01-25-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-26-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-27-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-28-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-29-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-30-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-31-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-11-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-12-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-13-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-14-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-15-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-16-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-17-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-18-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-19-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-20-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-21-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-22-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-23-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-24-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-25-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-26-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-27-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-28-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-29-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-01-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-02-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-03-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-04-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-05-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-06-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-08-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-09-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-10-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-11-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-12-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-13-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-14-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-15-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-16-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-17-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-18-2020': { confirmed: 1, recovered: 1, deaths: 0 },
	},
	Canada: {
		'01-26-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-27-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-28-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-29-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'01-30-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'01-31-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'02-03-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'02-04-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'02-05-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'02-06-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'02-07-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'02-08-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'02-09-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'02-10-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'02-11-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'02-12-2020': { confirmed: 7, recovered: 1, deaths: 0 },
		'02-13-2020': { confirmed: 7, recovered: 1, deaths: 0 },
		'02-14-2020': { confirmed: 7, recovered: 1, deaths: 0 },
		'02-15-2020': { confirmed: 7, recovered: 1, deaths: 0 },
		'02-16-2020': { confirmed: 7, recovered: 1, deaths: 0 },
		'02-17-2020': { confirmed: 8, recovered: 1, deaths: 0 },
		'02-18-2020': { confirmed: 8, recovered: 1, deaths: 0 },
		'02-19-2020': { confirmed: 8, recovered: 1, deaths: 0 },
		'02-20-2020': { confirmed: 8, recovered: 1, deaths: 0 },
		'02-21-2020': { confirmed: 9, recovered: 3, deaths: 0 },
		'02-22-2020': { confirmed: 9, recovered: 3, deaths: 0 },
		'02-23-2020': { confirmed: 9, recovered: 3, deaths: 0 },
		'02-24-2020': { confirmed: 10, recovered: 3, deaths: 0 },
		'02-25-2020': { confirmed: 11, recovered: 3, deaths: 0 },
		'02-26-2020': { confirmed: 11, recovered: 3, deaths: 0 },
		'02-27-2020': { confirmed: 13, recovered: 6, deaths: 0 },
		'02-28-2020': { confirmed: 14, recovered: 6, deaths: 0 },
		'02-29-2020': { confirmed: 20, recovered: 6, deaths: 0 },
		'03-01-2020': { confirmed: 24, recovered: 6, deaths: 0 },
		'03-02-2020': { confirmed: 27, recovered: 6, deaths: 0 },
		'03-03-2020': { confirmed: 30, recovered: 6, deaths: 0 },
		'03-04-2020': { confirmed: 33, recovered: 6, deaths: 0 },
		'03-05-2020': { confirmed: 37, recovered: 6, deaths: 0 },
		'03-06-2020': { confirmed: 49, recovered: 6, deaths: 0 },
		'03-07-2020': { confirmed: 54, recovered: 8, deaths: 0 },
		'03-08-2020': { confirmed: 64, recovered: 8, deaths: 0 },
		'03-09-2020': { confirmed: 76, recovered: 8, deaths: 1 },
		'03-10-2020': { confirmed: 79, recovered: 8, deaths: 1 },
		'03-11-2020': { confirmed: 108, recovered: 8, deaths: 1 },
		'03-12-2020': { confirmed: 117, recovered: 8, deaths: 1 },
		'03-13-2020': { confirmed: 193, recovered: 8, deaths: 1 },
		'03-14-2020': { confirmed: 196, recovered: 8, deaths: 1 },
		'03-15-2020': { confirmed: 250, recovered: 8, deaths: 1 },
		'03-16-2020': { confirmed: 415, recovered: 9, deaths: 4 },
		'03-17-2020': { confirmed: 478, recovered: 9, deaths: 5 },
		'03-18-2020': { confirmed: 657, recovered: 9, deaths: 8 },
	},
	Cambodia: {
		'01-27-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-28-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-29-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-30-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-31-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-11-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-12-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-13-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-14-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-15-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-16-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-17-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-18-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-19-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-20-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-21-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-22-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-23-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-24-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-25-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-26-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-27-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-28-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-29-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-01-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-02-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-03-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-04-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-05-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-06-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-08-2020': { confirmed: 2, recovered: 1, deaths: 0 },
		'03-09-2020': { confirmed: 2, recovered: 1, deaths: 0 },
		'03-10-2020': { confirmed: 2, recovered: 1, deaths: 0 },
		'03-11-2020': { confirmed: 3, recovered: 1, deaths: 0 },
		'03-12-2020': { confirmed: 3, recovered: 1, deaths: 0 },
		'03-13-2020': { confirmed: 5, recovered: 1, deaths: 0 },
		'03-14-2020': { confirmed: 7, recovered: 1, deaths: 0 },
		'03-15-2020': { confirmed: 7, recovered: 1, deaths: 0 },
		'03-16-2020': { confirmed: 7, recovered: 1, deaths: 0 },
		'03-17-2020': { confirmed: 33, recovered: 1, deaths: 0 },
		'03-18-2020': { confirmed: 35, recovered: 1, deaths: 0 },
	},
	'Sri Lanka': {
		'01-27-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-28-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-29-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-30-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-31-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-08-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-09-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-10-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-11-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-12-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-13-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-14-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-15-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-16-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-17-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-18-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-19-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-20-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-21-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-22-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-23-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-24-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-25-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-26-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-27-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-28-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-29-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-01-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-02-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-03-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-04-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-05-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-06-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-08-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-09-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-10-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-11-2020': { confirmed: 2, recovered: 1, deaths: 0 },
		'03-12-2020': { confirmed: 2, recovered: 1, deaths: 0 },
		'03-13-2020': { confirmed: 6, recovered: 1, deaths: 0 },
		'03-14-2020': { confirmed: 10, recovered: 1, deaths: 0 },
		'03-15-2020': { confirmed: 18, recovered: 1, deaths: 0 },
		'03-16-2020': { confirmed: 28, recovered: 1, deaths: 0 },
		'03-17-2020': { confirmed: 44, recovered: 1, deaths: 0 },
		'03-18-2020': { confirmed: 51, recovered: 1, deaths: 0 },
	},
	'Ivory Coast': { '01-27-2020': { confirmed: 1, recovered: 0, deaths: 0 } },
	Germany: {
		'01-28-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'01-29-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'01-30-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'01-31-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'02-03-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'02-04-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'02-05-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'02-06-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'02-07-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'02-08-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'02-09-2020': { confirmed: 14, recovered: 0, deaths: 0 },
		'02-10-2020': { confirmed: 14, recovered: 0, deaths: 0 },
		'02-11-2020': { confirmed: 16, recovered: 0, deaths: 0 },
		'02-12-2020': { confirmed: 16, recovered: 0, deaths: 0 },
		'02-13-2020': { confirmed: 16, recovered: 1, deaths: 0 },
		'02-14-2020': { confirmed: 16, recovered: 1, deaths: 0 },
		'02-15-2020': { confirmed: 16, recovered: 1, deaths: 0 },
		'02-16-2020': { confirmed: 16, recovered: 1, deaths: 0 },
		'02-17-2020': { confirmed: 16, recovered: 1, deaths: 0 },
		'02-18-2020': { confirmed: 16, recovered: 12, deaths: 0 },
		'02-19-2020': { confirmed: 16, recovered: 12, deaths: 0 },
		'02-20-2020': { confirmed: 16, recovered: 12, deaths: 0 },
		'02-21-2020': { confirmed: 16, recovered: 14, deaths: 0 },
		'02-22-2020': { confirmed: 16, recovered: 14, deaths: 0 },
		'02-23-2020': { confirmed: 16, recovered: 14, deaths: 0 },
		'02-24-2020': { confirmed: 16, recovered: 14, deaths: 0 },
		'02-25-2020': { confirmed: 17, recovered: 14, deaths: 0 },
		'02-26-2020': { confirmed: 27, recovered: 15, deaths: 0 },
		'02-27-2020': { confirmed: 46, recovered: 16, deaths: 0 },
		'02-28-2020': { confirmed: 48, recovered: 16, deaths: 0 },
		'02-29-2020': { confirmed: 79, recovered: 16, deaths: 0 },
		'03-01-2020': { confirmed: 130, recovered: 16, deaths: 0 },
		'03-02-2020': { confirmed: 159, recovered: 16, deaths: 0 },
		'03-03-2020': { confirmed: 196, recovered: 16, deaths: 0 },
		'03-04-2020': { confirmed: 262, recovered: 16, deaths: 0 },
		'03-05-2020': { confirmed: 482, recovered: 16, deaths: 0 },
		'03-06-2020': { confirmed: 670, recovered: 17, deaths: 0 },
		'03-07-2020': { confirmed: 799, recovered: 18, deaths: 0 },
		'03-08-2020': { confirmed: 1040, recovered: 18, deaths: 0 },
		'03-09-2020': { confirmed: 1176, recovered: 18, deaths: 2 },
		'03-10-2020': { confirmed: 1457, recovered: 18, deaths: 2 },
		'03-11-2020': { confirmed: 1908, recovered: 25, deaths: 3 },
		'03-12-2020': { confirmed: 2078, recovered: 25, deaths: 3 },
		'03-13-2020': { confirmed: 3675, recovered: 46, deaths: 7 },
		'03-14-2020': { confirmed: 4585, recovered: 46, deaths: 9 },
		'03-15-2020': { confirmed: 5795, recovered: 46, deaths: 11 },
		'03-16-2020': { confirmed: 7272, recovered: 67, deaths: 17 },
		'03-17-2020': { confirmed: 9257, recovered: 67, deaths: 24 },
		'03-18-2020': { confirmed: 12327, recovered: 105, deaths: 28 },
	},
	Finland: {
		'01-29-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-30-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-31-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-11-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-12-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-13-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-14-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-15-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-16-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-17-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-18-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-19-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-20-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-21-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-22-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-23-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-24-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-25-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-26-2020': { confirmed: 2, recovered: 1, deaths: 0 },
		'02-27-2020': { confirmed: 2, recovered: 1, deaths: 0 },
		'02-28-2020': { confirmed: 2, recovered: 1, deaths: 0 },
		'02-29-2020': { confirmed: 3, recovered: 1, deaths: 0 },
		'03-01-2020': { confirmed: 6, recovered: 1, deaths: 0 },
		'03-02-2020': { confirmed: 6, recovered: 1, deaths: 0 },
		'03-03-2020': { confirmed: 6, recovered: 1, deaths: 0 },
		'03-04-2020': { confirmed: 6, recovered: 1, deaths: 0 },
		'03-05-2020': { confirmed: 12, recovered: 1, deaths: 0 },
		'03-06-2020': { confirmed: 15, recovered: 1, deaths: 0 },
		'03-07-2020': { confirmed: 15, recovered: 1, deaths: 0 },
		'03-08-2020': { confirmed: 23, recovered: 1, deaths: 0 },
		'03-09-2020': { confirmed: 30, recovered: 1, deaths: 0 },
		'03-10-2020': { confirmed: 40, recovered: 1, deaths: 0 },
		'03-11-2020': { confirmed: 59, recovered: 1, deaths: 0 },
		'03-12-2020': { confirmed: 59, recovered: 1, deaths: 0 },
		'03-13-2020': { confirmed: 155, recovered: 1, deaths: 0 },
		'03-14-2020': { confirmed: 225, recovered: 1, deaths: 0 },
		'03-15-2020': { confirmed: 244, recovered: 10, deaths: 0 },
		'03-16-2020': { confirmed: 277, recovered: 10, deaths: 0 },
		'03-17-2020': { confirmed: 321, recovered: 10, deaths: 0 },
		'03-18-2020': { confirmed: 336, recovered: 10, deaths: 0 },
	},
	'United Arab Emirates': {
		'01-29-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'01-30-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'01-31-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'02-03-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'02-04-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'02-05-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'02-06-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'02-07-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'02-08-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'02-09-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'02-10-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'02-11-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'02-12-2020': { confirmed: 8, recovered: 1, deaths: 0 },
		'02-13-2020': { confirmed: 8, recovered: 1, deaths: 0 },
		'02-14-2020': { confirmed: 8, recovered: 1, deaths: 0 },
		'02-15-2020': { confirmed: 8, recovered: 3, deaths: 0 },
		'02-16-2020': { confirmed: 9, recovered: 4, deaths: 0 },
		'02-17-2020': { confirmed: 9, recovered: 4, deaths: 0 },
		'02-18-2020': { confirmed: 9, recovered: 4, deaths: 0 },
		'02-19-2020': { confirmed: 9, recovered: 4, deaths: 0 },
		'02-20-2020': { confirmed: 9, recovered: 4, deaths: 0 },
		'02-21-2020': { confirmed: 9, recovered: 4, deaths: 0 },
		'02-22-2020': { confirmed: 13, recovered: 4, deaths: 0 },
		'02-23-2020': { confirmed: 13, recovered: 4, deaths: 0 },
		'02-24-2020': { confirmed: 13, recovered: 4, deaths: 0 },
		'02-25-2020': { confirmed: 13, recovered: 4, deaths: 0 },
		'02-26-2020': { confirmed: 13, recovered: 4, deaths: 0 },
		'02-27-2020': { confirmed: 13, recovered: 4, deaths: 0 },
		'02-28-2020': { confirmed: 19, recovered: 5, deaths: 0 },
		'02-29-2020': { confirmed: 21, recovered: 5, deaths: 0 },
		'03-01-2020': { confirmed: 21, recovered: 5, deaths: 0 },
		'03-02-2020': { confirmed: 21, recovered: 5, deaths: 0 },
		'03-03-2020': { confirmed: 27, recovered: 5, deaths: 0 },
		'03-04-2020': { confirmed: 27, recovered: 5, deaths: 0 },
		'03-05-2020': { confirmed: 29, recovered: 5, deaths: 0 },
		'03-06-2020': { confirmed: 29, recovered: 5, deaths: 0 },
		'03-07-2020': { confirmed: 45, recovered: 7, deaths: 0 },
		'03-08-2020': { confirmed: 45, recovered: 7, deaths: 0 },
		'03-09-2020': { confirmed: 45, recovered: 7, deaths: 0 },
		'03-10-2020': { confirmed: 74, recovered: 12, deaths: 0 },
		'03-11-2020': { confirmed: 74, recovered: 17, deaths: 0 },
		'03-12-2020': { confirmed: 85, recovered: 17, deaths: 0 },
		'03-13-2020': { confirmed: 85, recovered: 17, deaths: 0 },
		'03-14-2020': { confirmed: 85, recovered: 17, deaths: 0 },
		'03-15-2020': { confirmed: 98, recovered: 23, deaths: 0 },
		'03-16-2020': { confirmed: 98, recovered: 23, deaths: 0 },
		'03-17-2020': { confirmed: 98, recovered: 23, deaths: 0 },
		'03-18-2020': { confirmed: 113, recovered: 26, deaths: 0 },
	},
	India: {
		'01-30-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'01-31-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-03-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-04-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-05-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-06-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-07-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-08-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-09-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-10-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-11-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-12-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-13-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-14-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-15-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-16-2020': { confirmed: 3, recovered: 3, deaths: 0 },
		'02-17-2020': { confirmed: 3, recovered: 3, deaths: 0 },
		'02-18-2020': { confirmed: 3, recovered: 3, deaths: 0 },
		'02-19-2020': { confirmed: 3, recovered: 3, deaths: 0 },
		'02-20-2020': { confirmed: 3, recovered: 3, deaths: 0 },
		'02-21-2020': { confirmed: 3, recovered: 3, deaths: 0 },
		'02-22-2020': { confirmed: 3, recovered: 3, deaths: 0 },
		'02-23-2020': { confirmed: 3, recovered: 3, deaths: 0 },
		'02-24-2020': { confirmed: 3, recovered: 3, deaths: 0 },
		'02-25-2020': { confirmed: 3, recovered: 3, deaths: 0 },
		'02-26-2020': { confirmed: 3, recovered: 3, deaths: 0 },
		'02-27-2020': { confirmed: 3, recovered: 3, deaths: 0 },
		'02-28-2020': { confirmed: 3, recovered: 3, deaths: 0 },
		'02-29-2020': { confirmed: 3, recovered: 3, deaths: 0 },
		'03-01-2020': { confirmed: 3, recovered: 3, deaths: 0 },
		'03-02-2020': { confirmed: 5, recovered: 3, deaths: 0 },
		'03-03-2020': { confirmed: 5, recovered: 3, deaths: 0 },
		'03-04-2020': { confirmed: 28, recovered: 3, deaths: 0 },
		'03-05-2020': { confirmed: 30, recovered: 3, deaths: 0 },
		'03-06-2020': { confirmed: 31, recovered: 3, deaths: 0 },
		'03-07-2020': { confirmed: 34, recovered: 3, deaths: 0 },
		'03-08-2020': { confirmed: 39, recovered: 3, deaths: 0 },
		'03-09-2020': { confirmed: 43, recovered: 3, deaths: 0 },
		'03-10-2020': { confirmed: 56, recovered: 4, deaths: 0 },
		'03-11-2020': { confirmed: 62, recovered: 4, deaths: 1 },
		'03-12-2020': { confirmed: 73, recovered: 4, deaths: 1 },
		'03-13-2020': { confirmed: 82, recovered: 4, deaths: 2 },
		'03-14-2020': { confirmed: 102, recovered: 4, deaths: 2 },
		'03-15-2020': { confirmed: 113, recovered: 13, deaths: 2 },
		'03-16-2020': { confirmed: 119, recovered: 13, deaths: 2 },
		'03-17-2020': { confirmed: 142, recovered: 14, deaths: 3 },
		'03-18-2020': { confirmed: 156, recovered: 14, deaths: 3 },
	},
	Italy: {
		'01-31-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-03-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-04-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-05-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-06-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-07-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-08-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-09-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-10-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-11-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-12-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-13-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-14-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-15-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-16-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-17-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-18-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-19-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-20-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-21-2020': { confirmed: 20, recovered: 0, deaths: 1 },
		'02-22-2020': { confirmed: 62, recovered: 1, deaths: 2 },
		'02-23-2020': { confirmed: 155, recovered: 2, deaths: 3 },
		'02-24-2020': { confirmed: 229, recovered: 1, deaths: 7 },
		'02-25-2020': { confirmed: 322, recovered: 1, deaths: 10 },
		'02-26-2020': { confirmed: 453, recovered: 3, deaths: 12 },
		'02-27-2020': { confirmed: 655, recovered: 45, deaths: 17 },
		'02-28-2020': { confirmed: 888, recovered: 46, deaths: 21 },
		'02-29-2020': { confirmed: 1128, recovered: 46, deaths: 29 },
		'03-01-2020': { confirmed: 1694, recovered: 83, deaths: 34 },
		'03-02-2020': { confirmed: 2036, recovered: 149, deaths: 52 },
		'03-03-2020': { confirmed: 2502, recovered: 160, deaths: 79 },
		'03-04-2020': { confirmed: 3089, recovered: 276, deaths: 107 },
		'03-05-2020': { confirmed: 3858, recovered: 414, deaths: 148 },
		'03-06-2020': { confirmed: 4636, recovered: 523, deaths: 197 },
		'03-07-2020': { confirmed: 5883, recovered: 589, deaths: 233 },
		'03-08-2020': { confirmed: 7375, recovered: 622, deaths: 366 },
		'03-09-2020': { confirmed: 9172, recovered: 724, deaths: 463 },
		'03-10-2020': { confirmed: 10149, recovered: 724, deaths: 631 },
		'03-11-2020': { confirmed: 12462, recovered: 1045, deaths: 827 },
		'03-12-2020': { confirmed: 12462, recovered: 1045, deaths: 827 },
		'03-13-2020': { confirmed: 17660, recovered: 1439, deaths: 1266 },
		'03-14-2020': { confirmed: 21157, recovered: 1966, deaths: 1441 },
		'03-15-2020': { confirmed: 24747, recovered: 2335, deaths: 1809 },
		'03-16-2020': { confirmed: 27980, recovered: 2749, deaths: 2158 },
		'03-17-2020': { confirmed: 31506, recovered: 2941, deaths: 2503 },
		'03-18-2020': { confirmed: 35713, recovered: 4025, deaths: 2978 },
	},
	UK: {
		'01-31-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-03-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-04-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-05-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-06-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-07-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-08-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-09-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-10-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'02-11-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'02-12-2020': { confirmed: 9, recovered: 1, deaths: 0 },
		'02-13-2020': { confirmed: 9, recovered: 1, deaths: 0 },
		'02-14-2020': { confirmed: 9, recovered: 1, deaths: 0 },
		'02-15-2020': { confirmed: 9, recovered: 1, deaths: 0 },
		'02-16-2020': { confirmed: 9, recovered: 8, deaths: 0 },
		'02-17-2020': { confirmed: 9, recovered: 8, deaths: 0 },
		'02-18-2020': { confirmed: 9, recovered: 8, deaths: 0 },
		'02-19-2020': { confirmed: 9, recovered: 8, deaths: 0 },
		'02-20-2020': { confirmed: 9, recovered: 8, deaths: 0 },
		'02-21-2020': { confirmed: 9, recovered: 8, deaths: 0 },
		'02-22-2020': { confirmed: 9, recovered: 8, deaths: 0 },
		'02-23-2020': { confirmed: 9, recovered: 8, deaths: 0 },
		'02-24-2020': { confirmed: 13, recovered: 8, deaths: 0 },
		'02-25-2020': { confirmed: 13, recovered: 8, deaths: 0 },
		'02-26-2020': { confirmed: 13, recovered: 8, deaths: 0 },
		'02-27-2020': { confirmed: 15, recovered: 8, deaths: 0 },
		'02-28-2020': { confirmed: 20, recovered: 8, deaths: 0 },
		'02-29-2020': { confirmed: 23, recovered: 8, deaths: 0 },
		'03-01-2020': { confirmed: 36, recovered: 8, deaths: 0 },
		'03-02-2020': { confirmed: 40, recovered: 8, deaths: 0 },
		'03-03-2020': { confirmed: 51, recovered: 8, deaths: 0 },
		'03-04-2020': { confirmed: 85, recovered: 8, deaths: 0 },
		'03-05-2020': { confirmed: 115, recovered: 8, deaths: 1 },
		'03-06-2020': { confirmed: 163, recovered: 8, deaths: 2 },
		'03-07-2020': { confirmed: 206, recovered: 18, deaths: 2 },
		'03-08-2020': { confirmed: 273, recovered: 18, deaths: 3 },
		'03-09-2020': { confirmed: 321, recovered: 18, deaths: 4 },
		'03-10-2020': { confirmed: 382, recovered: 18, deaths: 6 },
	},
	Russia: {
		'01-31-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-03-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-04-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-05-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-06-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-07-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-08-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-09-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-10-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-11-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-12-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-13-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-14-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-15-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-16-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-17-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-18-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-19-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-20-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-21-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-22-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-23-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-24-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-25-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-26-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-27-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-28-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-29-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'03-01-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'03-02-2020': { confirmed: 3, recovered: 2, deaths: 0 },
		'03-03-2020': { confirmed: 3, recovered: 2, deaths: 0 },
		'03-04-2020': { confirmed: 3, recovered: 2, deaths: 0 },
		'03-05-2020': { confirmed: 4, recovered: 2, deaths: 0 },
		'03-06-2020': { confirmed: 13, recovered: 2, deaths: 0 },
		'03-07-2020': { confirmed: 13, recovered: 2, deaths: 0 },
		'03-08-2020': { confirmed: 17, recovered: 3, deaths: 0 },
		'03-09-2020': { confirmed: 17, recovered: 3, deaths: 0 },
		'03-11-2020': { confirmed: 20, recovered: 3, deaths: 0 },
		'03-12-2020': { confirmed: 28, recovered: 3, deaths: 0 },
		'03-13-2020': { confirmed: 45, recovered: 3, deaths: 0 },
		'03-14-2020': { confirmed: 59, recovered: 8, deaths: 0 },
		'03-15-2020': { confirmed: 63, recovered: 8, deaths: 0 },
		'03-16-2020': { confirmed: 90, recovered: 8, deaths: 0 },
		'03-17-2020': { confirmed: 114, recovered: 8, deaths: 0 },
		'03-18-2020': { confirmed: 147, recovered: 8, deaths: 0 },
	},
	Sweden: {
		'01-31-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-11-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-12-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-13-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-18-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-19-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-20-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-21-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-22-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-23-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-24-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-25-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-26-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-27-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 14, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 15, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 21, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 35, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 94, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 101, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 161, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 203, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 248, recovered: 1, deaths: 0 },
		'03-10-2020': { confirmed: 355, recovered: 1, deaths: 0 },
		'03-11-2020': { confirmed: 500, recovered: 1, deaths: 1 },
		'03-12-2020': { confirmed: 599, recovered: 1, deaths: 1 },
		'03-13-2020': { confirmed: 814, recovered: 1, deaths: 1 },
		'03-14-2020': { confirmed: 961, recovered: 1, deaths: 2 },
		'03-15-2020': { confirmed: 1022, recovered: 1, deaths: 3 },
		'03-16-2020': { confirmed: 1103, recovered: 1, deaths: 6 },
		'03-17-2020': { confirmed: 1190, recovered: 1, deaths: 7 },
		'03-18-2020': { confirmed: 1279, recovered: 1, deaths: 10 },
	},
	Spain: {
		'02-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-09-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-10-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-11-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-12-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-13-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-14-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-15-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-16-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-17-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-18-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-19-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-20-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-21-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-22-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-23-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-24-2020': { confirmed: 2, recovered: 2, deaths: 0 },
		'02-25-2020': { confirmed: 6, recovered: 2, deaths: 0 },
		'02-26-2020': { confirmed: 13, recovered: 2, deaths: 0 },
		'02-27-2020': { confirmed: 15, recovered: 2, deaths: 0 },
		'02-28-2020': { confirmed: 32, recovered: 2, deaths: 0 },
		'02-29-2020': { confirmed: 45, recovered: 2, deaths: 0 },
		'03-01-2020': { confirmed: 84, recovered: 2, deaths: 0 },
		'03-02-2020': { confirmed: 120, recovered: 2, deaths: 0 },
		'03-03-2020': { confirmed: 165, recovered: 2, deaths: 1 },
		'03-04-2020': { confirmed: 222, recovered: 2, deaths: 2 },
		'03-05-2020': { confirmed: 259, recovered: 2, deaths: 3 },
		'03-06-2020': { confirmed: 400, recovered: 2, deaths: 5 },
		'03-07-2020': { confirmed: 500, recovered: 30, deaths: 10 },
		'03-08-2020': { confirmed: 673, recovered: 30, deaths: 17 },
		'03-09-2020': { confirmed: 1073, recovered: 32, deaths: 28 },
		'03-10-2020': { confirmed: 1695, recovered: 32, deaths: 35 },
		'03-11-2020': { confirmed: 2277, recovered: 183, deaths: 54 },
		'03-12-2020': { confirmed: 2277, recovered: 183, deaths: 55 },
		'03-13-2020': { confirmed: 5232, recovered: 193, deaths: 133 },
		'03-14-2020': { confirmed: 6391, recovered: 517, deaths: 195 },
		'03-15-2020': { confirmed: 7798, recovered: 517, deaths: 289 },
		'03-16-2020': { confirmed: 9942, recovered: 530, deaths: 342 },
		'03-17-2020': { confirmed: 11748, recovered: 1028, deaths: 533 },
		'03-18-2020': { confirmed: 13910, recovered: 1081, deaths: 623 },
	},
	Belgium: {
		'02-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-11-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-12-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-13-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-17-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-18-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-19-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-20-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-21-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-22-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-23-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-24-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-25-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-26-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-27-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-28-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-29-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-01-2020': { confirmed: 2, recovered: 1, deaths: 0 },
		'03-02-2020': { confirmed: 8, recovered: 1, deaths: 0 },
		'03-03-2020': { confirmed: 13, recovered: 1, deaths: 0 },
		'03-04-2020': { confirmed: 23, recovered: 1, deaths: 0 },
		'03-05-2020': { confirmed: 50, recovered: 1, deaths: 0 },
		'03-06-2020': { confirmed: 109, recovered: 1, deaths: 0 },
		'03-07-2020': { confirmed: 169, recovered: 1, deaths: 0 },
		'03-08-2020': { confirmed: 200, recovered: 1, deaths: 0 },
		'03-09-2020': { confirmed: 239, recovered: 1, deaths: 0 },
		'03-10-2020': { confirmed: 267, recovered: 1, deaths: 0 },
		'03-11-2020': { confirmed: 314, recovered: 1, deaths: 3 },
		'03-12-2020': { confirmed: 314, recovered: 1, deaths: 3 },
		'03-13-2020': { confirmed: 559, recovered: 1, deaths: 3 },
		'03-14-2020': { confirmed: 689, recovered: 1, deaths: 4 },
		'03-15-2020': { confirmed: 886, recovered: 1, deaths: 4 },
		'03-16-2020': { confirmed: 1058, recovered: 1, deaths: 5 },
		'03-17-2020': { confirmed: 1243, recovered: 1, deaths: 10 },
		'03-18-2020': { confirmed: 1486, recovered: 31, deaths: 14 },
	},
	Others: {
		'02-07-2020': { confirmed: 61, recovered: 0, deaths: 0 },
		'02-08-2020': { confirmed: 61, recovered: 0, deaths: 0 },
		'02-09-2020': { confirmed: 64, recovered: 0, deaths: 0 },
		'02-10-2020': { confirmed: 135, recovered: 0, deaths: 0 },
		'02-11-2020': { confirmed: 135, recovered: 0, deaths: 0 },
		'02-12-2020': { confirmed: 175, recovered: 0, deaths: 0 },
		'02-13-2020': { confirmed: 175, recovered: 0, deaths: 0 },
		'02-14-2020': { confirmed: 218, recovered: 0, deaths: 0 },
		'02-15-2020': { confirmed: 285, recovered: 0, deaths: 0 },
		'02-16-2020': { confirmed: 355, recovered: 0, deaths: 0 },
		'02-17-2020': { confirmed: 454, recovered: 0, deaths: 0 },
		'02-18-2020': { confirmed: 542, recovered: 0, deaths: 0 },
		'02-19-2020': { confirmed: 621, recovered: 1, deaths: 0 },
		'02-20-2020': { confirmed: 634, recovered: 1, deaths: 2 },
		'02-21-2020': { confirmed: 634, recovered: 1, deaths: 2 },
		'02-22-2020': { confirmed: 634, recovered: 1, deaths: 2 },
		'02-23-2020': { confirmed: 691, recovered: 0, deaths: 3 },
		'02-24-2020': { confirmed: 691, recovered: 0, deaths: 3 },
		'02-25-2020': { confirmed: 691, recovered: 0, deaths: 3 },
		'02-26-2020': { confirmed: 705, recovered: 10, deaths: 4 },
		'02-27-2020': { confirmed: 705, recovered: 10, deaths: 4 },
		'02-28-2020': { confirmed: 705, recovered: 10, deaths: 6 },
		'02-29-2020': { confirmed: 705, recovered: 10, deaths: 6 },
		'03-01-2020': { confirmed: 705, recovered: 10, deaths: 6 },
		'03-02-2020': { confirmed: 705, recovered: 10, deaths: 6 },
		'03-03-2020': { confirmed: 706, recovered: 10, deaths: 6 },
		'03-04-2020': { confirmed: 706, recovered: 10, deaths: 6 },
		'03-05-2020': { confirmed: 706, recovered: 10, deaths: 6 },
		'03-06-2020': { confirmed: 696, recovered: 40, deaths: 6 },
		'03-07-2020': { confirmed: 696, recovered: 40, deaths: 6 },
		'03-08-2020': { confirmed: 696, recovered: 40, deaths: 6 },
		'03-09-2020': { confirmed: 696, recovered: 40, deaths: 6 },
		'03-10-2020': { confirmed: 696, recovered: 40, deaths: 6 },
	},
	Egypt: {
		'02-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-18-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-19-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-20-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-21-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-22-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-23-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-24-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-25-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-26-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-27-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'02-29-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-01-2020': { confirmed: 2, recovered: 1, deaths: 0 },
		'03-02-2020': { confirmed: 2, recovered: 1, deaths: 0 },
		'03-03-2020': { confirmed: 2, recovered: 1, deaths: 0 },
		'03-04-2020': { confirmed: 2, recovered: 1, deaths: 0 },
		'03-05-2020': { confirmed: 3, recovered: 1, deaths: 0 },
		'03-06-2020': { confirmed: 15, recovered: 1, deaths: 0 },
		'03-07-2020': { confirmed: 15, recovered: 1, deaths: 0 },
		'03-08-2020': { confirmed: 49, recovered: 1, deaths: 1 },
		'03-09-2020': { confirmed: 55, recovered: 12, deaths: 1 },
		'03-10-2020': { confirmed: 59, recovered: 1, deaths: 1 },
		'03-11-2020': { confirmed: 60, recovered: 27, deaths: 1 },
		'03-12-2020': { confirmed: 67, recovered: 27, deaths: 1 },
		'03-13-2020': { confirmed: 80, recovered: 27, deaths: 2 },
		'03-14-2020': { confirmed: 109, recovered: 27, deaths: 2 },
		'03-15-2020': { confirmed: 110, recovered: 21, deaths: 2 },
		'03-16-2020': { confirmed: 150, recovered: 27, deaths: 2 },
		'03-17-2020': { confirmed: 196, recovered: 32, deaths: 4 },
		'03-18-2020': { confirmed: 196, recovered: 32, deaths: 6 },
	},
	Iran: {
		'02-19-2020': { confirmed: 2, recovered: 0, deaths: 2 },
		'02-20-2020': { confirmed: 5, recovered: 0, deaths: 2 },
		'02-21-2020': { confirmed: 18, recovered: 0, deaths: 4 },
		'02-22-2020': { confirmed: 28, recovered: 0, deaths: 5 },
		'02-23-2020': { confirmed: 43, recovered: 0, deaths: 8 },
		'02-24-2020': { confirmed: 61, recovered: 0, deaths: 12 },
		'02-25-2020': { confirmed: 95, recovered: 0, deaths: 16 },
		'02-26-2020': { confirmed: 139, recovered: 49, deaths: 19 },
		'02-27-2020': { confirmed: 245, recovered: 49, deaths: 26 },
		'02-28-2020': { confirmed: 388, recovered: 73, deaths: 34 },
		'02-29-2020': { confirmed: 593, recovered: 123, deaths: 43 },
		'03-01-2020': { confirmed: 978, recovered: 175, deaths: 54 },
		'03-02-2020': { confirmed: 1501, recovered: 291, deaths: 66 },
		'03-03-2020': { confirmed: 2336, recovered: 291, deaths: 77 },
		'03-04-2020': { confirmed: 2922, recovered: 552, deaths: 92 },
		'03-05-2020': { confirmed: 3513, recovered: 739, deaths: 107 },
		'03-06-2020': { confirmed: 4747, recovered: 913, deaths: 124 },
		'03-07-2020': { confirmed: 5823, recovered: 1669, deaths: 145 },
		'03-08-2020': { confirmed: 6566, recovered: 2134, deaths: 194 },
		'03-09-2020': { confirmed: 7161, recovered: 2394, deaths: 237 },
		'03-11-2020': { confirmed: 9000, recovered: 2959, deaths: 354 },
		'03-12-2020': { confirmed: 10075, recovered: 2959, deaths: 429 },
		'03-13-2020': { confirmed: 11364, recovered: 2959, deaths: 514 },
		'03-14-2020': { confirmed: 12729, recovered: 2959, deaths: 611 },
		'03-15-2020': { confirmed: 13938, recovered: 4590, deaths: 724 },
		'03-16-2020': { confirmed: 14991, recovered: 4590, deaths: 853 },
		'03-17-2020': { confirmed: 16169, recovered: 5389, deaths: 988 },
		'03-18-2020': { confirmed: 17361, recovered: 5389, deaths: 1135 },
	},
	Israel: {
		'02-21-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-22-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-23-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-24-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-25-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-26-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-27-2020': { confirmed: 3, recovered: 1, deaths: 0 },
		'02-28-2020': { confirmed: 4, recovered: 1, deaths: 0 },
		'02-29-2020': { confirmed: 7, recovered: 1, deaths: 0 },
		'03-01-2020': { confirmed: 10, recovered: 1, deaths: 0 },
		'03-02-2020': { confirmed: 10, recovered: 1, deaths: 0 },
		'03-03-2020': { confirmed: 12, recovered: 1, deaths: 0 },
		'03-04-2020': { confirmed: 15, recovered: 1, deaths: 0 },
		'03-05-2020': { confirmed: 16, recovered: 1, deaths: 0 },
		'03-06-2020': { confirmed: 21, recovered: 2, deaths: 0 },
		'03-07-2020': { confirmed: 21, recovered: 2, deaths: 0 },
		'03-08-2020': { confirmed: 39, recovered: 2, deaths: 0 },
		'03-09-2020': { confirmed: 39, recovered: 2, deaths: 0 },
		'03-10-2020': { confirmed: 58, recovered: 4, deaths: 0 },
		'03-11-2020': { confirmed: 109, recovered: 4, deaths: 0 },
		'03-12-2020': { confirmed: 131, recovered: 4, deaths: 0 },
		'03-13-2020': { confirmed: 161, recovered: 4, deaths: 0 },
		'03-14-2020': { confirmed: 193, recovered: 4, deaths: 0 },
		'03-15-2020': { confirmed: 251, recovered: 4, deaths: 0 },
		'03-16-2020': { confirmed: 255, recovered: 4, deaths: 0 },
		'03-17-2020': { confirmed: 337, recovered: 11, deaths: 0 },
		'03-18-2020': { confirmed: 433, recovered: 11, deaths: 0 },
	},
	Lebanon: {
		'02-21-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-22-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-23-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-24-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-25-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-26-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-27-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 13, recovered: 1, deaths: 0 },
		'03-05-2020': { confirmed: 16, recovered: 1, deaths: 0 },
		'03-06-2020': { confirmed: 22, recovered: 1, deaths: 0 },
		'03-07-2020': { confirmed: 22, recovered: 1, deaths: 0 },
		'03-08-2020': { confirmed: 32, recovered: 1, deaths: 0 },
		'03-09-2020': { confirmed: 32, recovered: 1, deaths: 0 },
		'03-10-2020': { confirmed: 41, recovered: 1, deaths: 1 },
		'03-11-2020': { confirmed: 61, recovered: 1, deaths: 3 },
		'03-12-2020': { confirmed: 61, recovered: 1, deaths: 3 },
		'03-13-2020': { confirmed: 77, recovered: 1, deaths: 3 },
		'03-14-2020': { confirmed: 93, recovered: 1, deaths: 3 },
		'03-15-2020': { confirmed: 110, recovered: 1, deaths: 3 },
		'03-16-2020': { confirmed: 99, recovered: 1, deaths: 3 },
		'03-17-2020': { confirmed: 120, recovered: 3, deaths: 3 },
		'03-18-2020': { confirmed: 133, recovered: 3, deaths: 3 },
	},
	Iraq: {
		'02-23-2020': { confirmed: 0, recovered: 0, deaths: 0 },
		'02-24-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-25-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-26-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'02-27-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 19, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 26, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 32, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 35, recovered: 0, deaths: 2 },
		'03-05-2020': { confirmed: 35, recovered: 0, deaths: 2 },
		'03-06-2020': { confirmed: 40, recovered: 0, deaths: 3 },
		'03-07-2020': { confirmed: 54, recovered: 0, deaths: 4 },
		'03-08-2020': { confirmed: 60, recovered: 0, deaths: 6 },
		'03-09-2020': { confirmed: 60, recovered: 9, deaths: 6 },
		'03-10-2020': { confirmed: 71, recovered: 3, deaths: 7 },
		'03-11-2020': { confirmed: 71, recovered: 15, deaths: 7 },
		'03-12-2020': { confirmed: 71, recovered: 15, deaths: 8 },
		'03-13-2020': { confirmed: 101, recovered: 24, deaths: 9 },
		'03-14-2020': { confirmed: 110, recovered: 26, deaths: 10 },
		'03-15-2020': { confirmed: 116, recovered: 26, deaths: 10 },
		'03-16-2020': { confirmed: 124, recovered: 26, deaths: 10 },
		'03-17-2020': { confirmed: 154, recovered: 32, deaths: 11 },
		'03-18-2020': { confirmed: 164, recovered: 43, deaths: 12 },
	},
	Oman: {
		'02-24-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-25-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-26-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'02-27-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 6, recovered: 1, deaths: 0 },
		'03-01-2020': { confirmed: 6, recovered: 1, deaths: 0 },
		'03-02-2020': { confirmed: 6, recovered: 1, deaths: 0 },
		'03-03-2020': { confirmed: 12, recovered: 2, deaths: 0 },
		'03-04-2020': { confirmed: 15, recovered: 2, deaths: 0 },
		'03-05-2020': { confirmed: 16, recovered: 2, deaths: 0 },
		'03-06-2020': { confirmed: 16, recovered: 2, deaths: 0 },
		'03-07-2020': { confirmed: 16, recovered: 2, deaths: 0 },
		'03-08-2020': { confirmed: 16, recovered: 2, deaths: 0 },
		'03-09-2020': { confirmed: 16, recovered: 2, deaths: 0 },
		'03-10-2020': { confirmed: 18, recovered: 9, deaths: 0 },
		'03-11-2020': { confirmed: 18, recovered: 9, deaths: 0 },
		'03-12-2020': { confirmed: 18, recovered: 9, deaths: 0 },
		'03-13-2020': { confirmed: 19, recovered: 9, deaths: 0 },
		'03-14-2020': { confirmed: 19, recovered: 9, deaths: 0 },
		'03-15-2020': { confirmed: 22, recovered: 9, deaths: 0 },
		'03-16-2020': { confirmed: 22, recovered: 9, deaths: 0 },
		'03-17-2020': { confirmed: 24, recovered: 9, deaths: 0 },
		'03-18-2020': { confirmed: 39, recovered: 12, deaths: 0 },
	},
	Afghanistan: {
		'02-24-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-25-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-26-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-27-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 16, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 21, recovered: 1, deaths: 0 },
		'03-17-2020': { confirmed: 22, recovered: 1, deaths: 0 },
		'03-18-2020': { confirmed: 22, recovered: 1, deaths: 0 },
	},
	Bahrain: {
		'02-24-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-25-2020': { confirmed: 23, recovered: 0, deaths: 0 },
		'02-26-2020': { confirmed: 33, recovered: 0, deaths: 0 },
		'02-27-2020': { confirmed: 33, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 36, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 41, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 47, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 49, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 49, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 52, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 55, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 60, recovered: 4, deaths: 0 },
		'03-07-2020': { confirmed: 85, recovered: 4, deaths: 0 },
		'03-08-2020': { confirmed: 85, recovered: 4, deaths: 0 },
		'03-09-2020': { confirmed: 95, recovered: 14, deaths: 0 },
		'03-10-2020': { confirmed: 110, recovered: 22, deaths: 0 },
		'03-11-2020': { confirmed: 195, recovered: 35, deaths: 0 },
		'03-12-2020': { confirmed: 195, recovered: 35, deaths: 0 },
		'03-13-2020': { confirmed: 189, recovered: 44, deaths: 0 },
		'03-14-2020': { confirmed: 210, recovered: 44, deaths: 0 },
		'03-15-2020': { confirmed: 214, recovered: 60, deaths: 0 },
		'03-16-2020': { confirmed: 214, recovered: 77, deaths: 1 },
		'03-17-2020': { confirmed: 228, recovered: 81, deaths: 1 },
		'03-18-2020': { confirmed: 256, recovered: 88, deaths: 1 },
	},
	Kuwait: {
		'02-24-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-25-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'02-26-2020': { confirmed: 26, recovered: 0, deaths: 0 },
		'02-27-2020': { confirmed: 43, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 45, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 45, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 45, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 56, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 56, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 56, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 58, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 58, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 61, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 64, recovered: 1, deaths: 0 },
		'03-09-2020': { confirmed: 64, recovered: 1, deaths: 0 },
		'03-10-2020': { confirmed: 69, recovered: 1, deaths: 0 },
		'03-11-2020': { confirmed: 72, recovered: 2, deaths: 0 },
		'03-12-2020': { confirmed: 80, recovered: 5, deaths: 0 },
		'03-13-2020': { confirmed: 80, recovered: 5, deaths: 0 },
		'03-14-2020': { confirmed: 104, recovered: 5, deaths: 0 },
		'03-15-2020': { confirmed: 112, recovered: 5, deaths: 0 },
		'03-16-2020': { confirmed: 123, recovered: 9, deaths: 0 },
		'03-17-2020': { confirmed: 130, recovered: 9, deaths: 0 },
		'03-18-2020': { confirmed: 142, recovered: 15, deaths: 0 },
	},
	Austria: {
		'02-25-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-26-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-27-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 9, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 14, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 18, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 21, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 29, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 41, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 55, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 79, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 104, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 131, recovered: 2, deaths: 0 },
		'03-10-2020': { confirmed: 182, recovered: 4, deaths: 0 },
		'03-11-2020': { confirmed: 246, recovered: 4, deaths: 0 },
		'03-12-2020': { confirmed: 302, recovered: 4, deaths: 1 },
		'03-13-2020': { confirmed: 504, recovered: 6, deaths: 1 },
		'03-14-2020': { confirmed: 655, recovered: 6, deaths: 1 },
		'03-15-2020': { confirmed: 860, recovered: 6, deaths: 1 },
		'03-16-2020': { confirmed: 1018, recovered: 6, deaths: 3 },
		'03-17-2020': { confirmed: 1332, recovered: 1, deaths: 3 },
		'03-18-2020': { confirmed: 1646, recovered: 9, deaths: 4 },
	},
	Algeria: {
		'02-25-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-26-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-27-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 17, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 17, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 19, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 20, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 20, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 20, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 24, recovered: 8, deaths: 1 },
		'03-13-2020': { confirmed: 26, recovered: 8, deaths: 2 },
		'03-14-2020': { confirmed: 37, recovered: 12, deaths: 3 },
		'03-15-2020': { confirmed: 48, recovered: 12, deaths: 4 },
		'03-16-2020': { confirmed: 54, recovered: 12, deaths: 4 },
		'03-17-2020': { confirmed: 60, recovered: 12, deaths: 4 },
		'03-18-2020': { confirmed: 74, recovered: 12, deaths: 7 },
	},
	Croatia: {
		'02-25-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-26-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-27-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 9, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 14, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 19, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 19, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 32, recovered: 1, deaths: 0 },
		'03-14-2020': { confirmed: 38, recovered: 1, deaths: 0 },
		'03-15-2020': { confirmed: 49, recovered: 1, deaths: 0 },
		'03-16-2020': { confirmed: 57, recovered: 2, deaths: 0 },
		'03-17-2020': { confirmed: 65, recovered: 4, deaths: 0 },
		'03-18-2020': { confirmed: 81, recovered: 4, deaths: 0 },
	},
	Switzerland: {
		'02-25-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-26-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-27-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 18, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 27, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 42, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 56, recovered: 2, deaths: 0 },
		'03-04-2020': { confirmed: 90, recovered: 3, deaths: 0 },
		'03-05-2020': { confirmed: 114, recovered: 3, deaths: 1 },
		'03-06-2020': { confirmed: 214, recovered: 3, deaths: 1 },
		'03-07-2020': { confirmed: 268, recovered: 3, deaths: 1 },
		'03-08-2020': { confirmed: 337, recovered: 3, deaths: 2 },
		'03-09-2020': { confirmed: 374, recovered: 3, deaths: 2 },
		'03-10-2020': { confirmed: 491, recovered: 3, deaths: 3 },
		'03-11-2020': { confirmed: 652, recovered: 4, deaths: 4 },
		'03-12-2020': { confirmed: 652, recovered: 4, deaths: 4 },
		'03-13-2020': { confirmed: 1139, recovered: 4, deaths: 11 },
		'03-14-2020': { confirmed: 1359, recovered: 4, deaths: 13 },
		'03-15-2020': { confirmed: 2200, recovered: 4, deaths: 14 },
		'03-16-2020': { confirmed: 2200, recovered: 4, deaths: 14 },
		'03-17-2020': { confirmed: 2700, recovered: 4, deaths: 27 },
		'03-18-2020': { confirmed: 3028, recovered: 15, deaths: 28 },
	},
	Pakistan: {
		'02-26-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-27-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 6, recovered: 1, deaths: 0 },
		'03-09-2020': { confirmed: 6, recovered: 1, deaths: 0 },
		'03-10-2020': { confirmed: 16, recovered: 1, deaths: 0 },
		'03-11-2020': { confirmed: 19, recovered: 2, deaths: 0 },
		'03-12-2020': { confirmed: 20, recovered: 2, deaths: 0 },
		'03-13-2020': { confirmed: 28, recovered: 2, deaths: 0 },
		'03-14-2020': { confirmed: 31, recovered: 2, deaths: 0 },
		'03-15-2020': { confirmed: 53, recovered: 2, deaths: 0 },
		'03-16-2020': { confirmed: 136, recovered: 2, deaths: 0 },
		'03-17-2020': { confirmed: 236, recovered: 2, deaths: 0 },
		'03-18-2020': { confirmed: 299, recovered: 2, deaths: 0 },
	},
	Georgia: {
		'02-26-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-27-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 15, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 15, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 24, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 24, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 25, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 30, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 33, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 33, recovered: 1, deaths: 0 },
		'03-17-2020': { confirmed: 34, recovered: 1, deaths: 0 },
		'03-18-2020': { confirmed: 38, recovered: 1, deaths: 0 },
	},
	Greece: {
		'02-26-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-27-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 9, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 31, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 45, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 46, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 73, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 73, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 89, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 99, recovered: 0, deaths: 1 },
		'03-12-2020': { confirmed: 99, recovered: 0, deaths: 1 },
		'03-13-2020': { confirmed: 190, recovered: 0, deaths: 1 },
		'03-14-2020': { confirmed: 228, recovered: 8, deaths: 3 },
		'03-15-2020': { confirmed: 331, recovered: 8, deaths: 4 },
		'03-16-2020': { confirmed: 331, recovered: 8, deaths: 4 },
		'03-17-2020': { confirmed: 387, recovered: 8, deaths: 5 },
		'03-18-2020': { confirmed: 418, recovered: 8, deaths: 5 },
	},
	'North Macedonia': {
		'02-26-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-27-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 14, recovered: 1, deaths: 0 },
		'03-14-2020': { confirmed: 14, recovered: 1, deaths: 0 },
		'03-15-2020': { confirmed: 14, recovered: 1, deaths: 0 },
		'03-16-2020': { confirmed: 18, recovered: 1, deaths: 0 },
		'03-17-2020': { confirmed: 26, recovered: 1, deaths: 0 },
		'03-18-2020': { confirmed: 35, recovered: 1, deaths: 0 },
	},
	Norway: {
		'02-26-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-27-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 15, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 19, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 25, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 32, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 56, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 87, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 108, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 147, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 176, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 205, recovered: 1, deaths: 0 },
		'03-10-2020': { confirmed: 400, recovered: 1, deaths: 0 },
		'03-11-2020': { confirmed: 598, recovered: 1, deaths: 0 },
		'03-12-2020': { confirmed: 702, recovered: 1, deaths: 0 },
		'03-13-2020': { confirmed: 996, recovered: 1, deaths: 0 },
		'03-14-2020': { confirmed: 1090, recovered: 1, deaths: 3 },
		'03-15-2020': { confirmed: 1221, recovered: 1, deaths: 3 },
		'03-16-2020': { confirmed: 1333, recovered: 1, deaths: 3 },
		'03-17-2020': { confirmed: 1463, recovered: 1, deaths: 3 },
		'03-18-2020': { confirmed: 1550, recovered: 1, deaths: 6 },
	},
	Romania: {
		'02-26-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-27-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 4, recovered: 1, deaths: 0 },
		'03-05-2020': { confirmed: 6, recovered: 1, deaths: 0 },
		'03-06-2020': { confirmed: 9, recovered: 1, deaths: 0 },
		'03-07-2020': { confirmed: 9, recovered: 3, deaths: 0 },
		'03-08-2020': { confirmed: 15, recovered: 3, deaths: 0 },
		'03-09-2020': { confirmed: 15, recovered: 3, deaths: 0 },
		'03-10-2020': { confirmed: 25, recovered: 3, deaths: 0 },
		'03-11-2020': { confirmed: 45, recovered: 6, deaths: 0 },
		'03-12-2020': { confirmed: 49, recovered: 6, deaths: 0 },
		'03-13-2020': { confirmed: 89, recovered: 7, deaths: 0 },
		'03-14-2020': { confirmed: 123, recovered: 9, deaths: 0 },
		'03-15-2020': { confirmed: 131, recovered: 9, deaths: 0 },
		'03-16-2020': { confirmed: 158, recovered: 9, deaths: 0 },
		'03-17-2020': { confirmed: 184, recovered: 16, deaths: 0 },
		'03-18-2020': { confirmed: 260, recovered: 19, deaths: 0 },
	},
	Denmark: {
		'02-27-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 23, recovered: 1, deaths: 0 },
		'03-07-2020': { confirmed: 23, recovered: 1, deaths: 0 },
		'03-08-2020': { confirmed: 35, recovered: 1, deaths: 0 },
		'03-09-2020': { confirmed: 90, recovered: 1, deaths: 0 },
		'03-10-2020': { confirmed: 262, recovered: 1, deaths: 0 },
		'03-11-2020': { confirmed: 444, recovered: 1, deaths: 0 },
		'03-12-2020': { confirmed: 617, recovered: 1, deaths: 0 },
		'03-13-2020': { confirmed: 804, recovered: 1, deaths: 0 },
		'03-14-2020': { confirmed: 836, recovered: 1, deaths: 1 },
		'03-15-2020': { confirmed: 875, recovered: 1, deaths: 2 },
		'03-16-2020': { confirmed: 932, recovered: 1, deaths: 3 },
		'03-17-2020': { confirmed: 1024, recovered: 1, deaths: 4 },
		'03-18-2020': { confirmed: 1115, recovered: 1, deaths: 4 },
	},
	Estonia: {
		'02-27-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 16, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 16, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 79, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 115, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 171, recovered: 1, deaths: 0 },
		'03-16-2020': { confirmed: 205, recovered: 1, deaths: 0 },
		'03-17-2020': { confirmed: 225, recovered: 1, deaths: 0 },
		'03-18-2020': { confirmed: 258, recovered: 1, deaths: 0 },
	},
	Netherlands: {
		'02-27-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 18, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 24, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 38, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 82, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 128, recovered: 0, deaths: 1 },
		'03-07-2020': { confirmed: 188, recovered: 0, deaths: 1 },
		'03-08-2020': { confirmed: 265, recovered: 0, deaths: 3 },
		'03-09-2020': { confirmed: 321, recovered: 0, deaths: 3 },
		'03-10-2020': { confirmed: 382, recovered: 0, deaths: 4 },
		'03-11-2020': { confirmed: 503, recovered: 0, deaths: 5 },
		'03-12-2020': { confirmed: 503, recovered: 0, deaths: 5 },
		'03-13-2020': { confirmed: 804, recovered: 0, deaths: 10 },
		'03-14-2020': { confirmed: 959, recovered: 2, deaths: 12 },
		'03-15-2020': { confirmed: 1135, recovered: 2, deaths: 20 },
		'03-16-2020': { confirmed: 1414, recovered: 2, deaths: 24 },
		'03-17-2020': { confirmed: 1708, recovered: 2, deaths: 43 },
		'03-18-2020': { confirmed: 2056, recovered: 2, deaths: 58 },
	},
	'San Marino': {
		'02-27-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-28-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 10, recovered: 0, deaths: 1 },
		'03-04-2020': { confirmed: 16, recovered: 0, deaths: 1 },
		'03-05-2020': { confirmed: 21, recovered: 0, deaths: 1 },
		'03-06-2020': { confirmed: 21, recovered: 0, deaths: 1 },
		'03-07-2020': { confirmed: 23, recovered: 0, deaths: 1 },
		'03-08-2020': { confirmed: 36, recovered: 0, deaths: 1 },
		'03-09-2020': { confirmed: 36, recovered: 0, deaths: 1 },
		'03-10-2020': { confirmed: 51, recovered: 0, deaths: 2 },
		'03-11-2020': { confirmed: 62, recovered: 0, deaths: 2 },
		'03-12-2020': { confirmed: 69, recovered: 0, deaths: 3 },
		'03-13-2020': { confirmed: 80, recovered: 0, deaths: 5 },
		'03-14-2020': { confirmed: 80, recovered: 4, deaths: 5 },
		'03-15-2020': { confirmed: 101, recovered: 4, deaths: 5 },
		'03-16-2020': { confirmed: 109, recovered: 4, deaths: 7 },
		'03-17-2020': { confirmed: 109, recovered: 4, deaths: 7 },
		'03-18-2020': { confirmed: 119, recovered: 4, deaths: 11 },
	},
	' Azerbaijan': { '02-28-2020': { confirmed: 1, recovered: 0, deaths: 0 } },
	Belarus: {
		'02-28-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 6, recovered: 1, deaths: 0 },
		'03-10-2020': { confirmed: 9, recovered: 3, deaths: 0 },
		'03-11-2020': { confirmed: 9, recovered: 3, deaths: 0 },
		'03-12-2020': { confirmed: 12, recovered: 3, deaths: 0 },
		'03-13-2020': { confirmed: 27, recovered: 3, deaths: 0 },
		'03-14-2020': { confirmed: 27, recovered: 3, deaths: 0 },
		'03-15-2020': { confirmed: 27, recovered: 3, deaths: 0 },
		'03-16-2020': { confirmed: 36, recovered: 3, deaths: 0 },
		'03-17-2020': { confirmed: 36, recovered: 3, deaths: 0 },
		'03-18-2020': { confirmed: 51, recovered: 5, deaths: 0 },
	},
	Iceland: {
		'02-28-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 26, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 34, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 43, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 50, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 50, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 58, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 69, recovered: 1, deaths: 0 },
		'03-11-2020': { confirmed: 85, recovered: 1, deaths: 0 },
		'03-12-2020': { confirmed: 103, recovered: 1, deaths: 0 },
		'03-13-2020': { confirmed: 134, recovered: 1, deaths: 0 },
		'03-14-2020': { confirmed: 156, recovered: 1, deaths: 0 },
		'03-15-2020': { confirmed: 171, recovered: 8, deaths: 5 },
		'03-16-2020': { confirmed: 180, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 220, recovered: 0, deaths: 1 },
		'03-18-2020': { confirmed: 250, recovered: 5, deaths: 1 },
	},
	Lithuania: {
		'02-28-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 12, recovered: 1, deaths: 0 },
		'03-16-2020': { confirmed: 17, recovered: 1, deaths: 0 },
		'03-17-2020': { confirmed: 25, recovered: 1, deaths: 0 },
		'03-18-2020': { confirmed: 27, recovered: 1, deaths: 0 },
	},
	'New Zealand': {
		'02-28-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 20, recovered: 0, deaths: 0 },
	},
	Nigeria: {
		'02-28-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'02-29-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 8, recovered: 1, deaths: 0 },
	},
	'North Ireland': { '02-28-2020': { confirmed: 1, recovered: 0, deaths: 0 } },
	Ireland: {
		'02-29-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 18, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 18, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 19, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 21, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 34, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 43, recovered: 0, deaths: 1 },
		'03-12-2020': { confirmed: 43, recovered: 0, deaths: 1 },
		'03-13-2020': { confirmed: 90, recovered: 0, deaths: 1 },
		'03-14-2020': { confirmed: 129, recovered: 0, deaths: 2 },
		'03-15-2020': { confirmed: 129, recovered: 0, deaths: 2 },
		'03-16-2020': { confirmed: 169, recovered: 0, deaths: 2 },
		'03-17-2020': { confirmed: 223, recovered: 5, deaths: 2 },
		'03-18-2020': { confirmed: 292, recovered: 5, deaths: 2 },
	},
	Luxembourg: {
		'02-29-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 19, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 34, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 51, recovered: 0, deaths: 1 },
		'03-15-2020': { confirmed: 59, recovered: 0, deaths: 1 },
		'03-16-2020': { confirmed: 77, recovered: 0, deaths: 1 },
		'03-17-2020': { confirmed: 140, recovered: 0, deaths: 1 },
		'03-18-2020': { confirmed: 203, recovered: 0, deaths: 2 },
	},
	Monaco: {
		'02-29-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 7, recovered: 0, deaths: 0 },
	},
	Qatar: {
		'02-29-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-01-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 15, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 18, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 24, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 262, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 262, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 320, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 337, recovered: 4, deaths: 0 },
		'03-15-2020': { confirmed: 401, recovered: 4, deaths: 0 },
		'03-16-2020': { confirmed: 439, recovered: 4, deaths: 0 },
		'03-17-2020': { confirmed: 439, recovered: 4, deaths: 0 },
		'03-18-2020': { confirmed: 452, recovered: 4, deaths: 0 },
	},
	Ecuador: {
		'03-01-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 14, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 15, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 15, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 17, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 17, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 17, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 28, recovered: 0, deaths: 2 },
		'03-15-2020': { confirmed: 28, recovered: 0, deaths: 2 },
		'03-16-2020': { confirmed: 37, recovered: 0, deaths: 2 },
		'03-17-2020': { confirmed: 58, recovered: 0, deaths: 2 },
		'03-18-2020': { confirmed: 111, recovered: 0, deaths: 2 },
	},
	Azerbaijan: {
		'03-01-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 9, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 9, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 9, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 11, recovered: 3, deaths: 0 },
		'03-12-2020': { confirmed: 11, recovered: 3, deaths: 0 },
		'03-13-2020': { confirmed: 15, recovered: 3, deaths: 1 },
		'03-14-2020': { confirmed: 15, recovered: 3, deaths: 1 },
		'03-15-2020': { confirmed: 23, recovered: 6, deaths: 1 },
		'03-16-2020': { confirmed: 15, recovered: 6, deaths: 1 },
		'03-17-2020': { confirmed: 28, recovered: 6, deaths: 1 },
		'03-18-2020': { confirmed: 28, recovered: 6, deaths: 1 },
	},
	'Czech Republic': {
		'03-01-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 18, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 19, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 31, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 31, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 41, recovered: 0, deaths: 0 },
	},
	Armenia: {
		'03-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 18, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 26, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 52, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 78, recovered: 1, deaths: 0 },
		'03-18-2020': { confirmed: 84, recovered: 1, deaths: 0 },
	},
	'Dominican Republic': {
		'03-01-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 21, recovered: 0, deaths: 1 },
		'03-18-2020': { confirmed: 21, recovered: 0, deaths: 1 },
	},
	Indonesia: {
		'03-02-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 19, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 27, recovered: 2, deaths: 0 },
		'03-11-2020': { confirmed: 34, recovered: 2, deaths: 1 },
		'03-12-2020': { confirmed: 34, recovered: 2, deaths: 1 },
		'03-13-2020': { confirmed: 69, recovered: 2, deaths: 4 },
		'03-14-2020': { confirmed: 96, recovered: 8, deaths: 5 },
		'03-15-2020': { confirmed: 117, recovered: 8, deaths: 5 },
		'03-16-2020': { confirmed: 134, recovered: 8, deaths: 5 },
		'03-17-2020': { confirmed: 172, recovered: 8, deaths: 5 },
		'03-18-2020': { confirmed: 227, recovered: 11, deaths: 19 },
	},
	Portugal: {
		'03-02-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 20, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 30, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 30, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 41, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 59, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 59, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 112, recovered: 1, deaths: 0 },
		'03-14-2020': { confirmed: 169, recovered: 2, deaths: 0 },
		'03-15-2020': { confirmed: 245, recovered: 2, deaths: 0 },
		'03-16-2020': { confirmed: 331, recovered: 3, deaths: 0 },
		'03-17-2020': { confirmed: 448, recovered: 3, deaths: 1 },
		'03-18-2020': { confirmed: 448, recovered: 3, deaths: 2 },
	},
	Andorra: {
		'03-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-13-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-15-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-16-2020': { confirmed: 2, recovered: 1, deaths: 0 },
		'03-17-2020': { confirmed: 39, recovered: 1, deaths: 0 },
		'03-18-2020': { confirmed: 39, recovered: 1, deaths: 0 },
	},
	Latvia: {
		'03-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 8, recovered: 1, deaths: 0 },
		'03-11-2020': { confirmed: 10, recovered: 1, deaths: 0 },
		'03-12-2020': { confirmed: 10, recovered: 1, deaths: 0 },
		'03-13-2020': { confirmed: 17, recovered: 1, deaths: 0 },
		'03-14-2020': { confirmed: 26, recovered: 1, deaths: 0 },
		'03-15-2020': { confirmed: 30, recovered: 1, deaths: 0 },
		'03-16-2020': { confirmed: 34, recovered: 1, deaths: 0 },
		'03-17-2020': { confirmed: 49, recovered: 1, deaths: 0 },
		'03-18-2020': { confirmed: 71, recovered: 1, deaths: 0 },
	},
	Morocco: {
		'03-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 3, recovered: 0, deaths: 1 },
		'03-11-2020': { confirmed: 5, recovered: 0, deaths: 1 },
		'03-12-2020': { confirmed: 6, recovered: 0, deaths: 1 },
		'03-13-2020': { confirmed: 7, recovered: 1, deaths: 1 },
		'03-14-2020': { confirmed: 17, recovered: 1, deaths: 1 },
		'03-15-2020': { confirmed: 28, recovered: 1, deaths: 1 },
		'03-16-2020': { confirmed: 29, recovered: 1, deaths: 1 },
		'03-17-2020': { confirmed: 38, recovered: 1, deaths: 2 },
		'03-18-2020': { confirmed: 49, recovered: 1, deaths: 2 },
	},
	'Saudi Arabia': {
		'03-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 15, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 20, recovered: 1, deaths: 0 },
		'03-11-2020': { confirmed: 21, recovered: 1, deaths: 0 },
		'03-12-2020': { confirmed: 45, recovered: 1, deaths: 0 },
		'03-13-2020': { confirmed: 86, recovered: 1, deaths: 0 },
		'03-14-2020': { confirmed: 103, recovered: 1, deaths: 0 },
		'03-15-2020': { confirmed: 103, recovered: 1, deaths: 0 },
		'03-16-2020': { confirmed: 118, recovered: 2, deaths: 0 },
		'03-17-2020': { confirmed: 171, recovered: 6, deaths: 0 },
		'03-18-2020': { confirmed: 171, recovered: 6, deaths: 0 },
	},
	Senegal: {
		'03-02-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-03-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 4, recovered: 1, deaths: 0 },
		'03-09-2020': { confirmed: 4, recovered: 1, deaths: 0 },
		'03-10-2020': { confirmed: 4, recovered: 1, deaths: 0 },
		'03-11-2020': { confirmed: 4, recovered: 1, deaths: 0 },
		'03-12-2020': { confirmed: 4, recovered: 1, deaths: 0 },
		'03-13-2020': { confirmed: 10, recovered: 1, deaths: 0 },
		'03-14-2020': { confirmed: 10, recovered: 1, deaths: 0 },
		'03-15-2020': { confirmed: 24, recovered: 1, deaths: 0 },
		'03-16-2020': { confirmed: 24, recovered: 2, deaths: 0 },
		'03-17-2020': { confirmed: 26, recovered: 2, deaths: 0 },
		'03-18-2020': { confirmed: 31, recovered: 2, deaths: 0 },
	},
	Argentina: {
		'03-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 12, recovered: 0, deaths: 1 },
		'03-09-2020': { confirmed: 12, recovered: 0, deaths: 1 },
		'03-10-2020': { confirmed: 17, recovered: 0, deaths: 1 },
		'03-11-2020': { confirmed: 19, recovered: 0, deaths: 1 },
		'03-12-2020': { confirmed: 19, recovered: 0, deaths: 1 },
		'03-13-2020': { confirmed: 31, recovered: 0, deaths: 2 },
		'03-14-2020': { confirmed: 34, recovered: 1, deaths: 2 },
		'03-15-2020': { confirmed: 45, recovered: 1, deaths: 2 },
		'03-16-2020': { confirmed: 56, recovered: 1, deaths: 2 },
		'03-17-2020': { confirmed: 68, recovered: 3, deaths: 2 },
		'03-18-2020': { confirmed: 79, recovered: 3, deaths: 2 },
	},
	Chile: {
		'03-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 23, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 23, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 43, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 61, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 74, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 155, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 201, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 238, recovered: 0, deaths: 0 },
	},
	Jordan: {
		'03-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-14-2020': { confirmed: 1, recovered: 1, deaths: 0 },
		'03-15-2020': { confirmed: 8, recovered: 1, deaths: 0 },
		'03-16-2020': { confirmed: 17, recovered: 1, deaths: 0 },
		'03-17-2020': { confirmed: 34, recovered: 1, deaths: 0 },
		'03-18-2020': { confirmed: 52, recovered: 1, deaths: 0 },
	},
	Ukraine: {
		'03-03-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 3, recovered: 0, deaths: 1 },
		'03-14-2020': { confirmed: 3, recovered: 0, deaths: 1 },
		'03-15-2020': { confirmed: 3, recovered: 0, deaths: 1 },
		'03-16-2020': { confirmed: 7, recovered: 0, deaths: 1 },
		'03-17-2020': { confirmed: 14, recovered: 0, deaths: 2 },
		'03-18-2020': { confirmed: 14, recovered: 0, deaths: 2 },
	},
	'Saint Barthelemy': {
		'03-04-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
	},
	Hungary: {
		'03-04-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 9, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 9, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 19, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 30, recovered: 1, deaths: 0 },
		'03-15-2020': { confirmed: 32, recovered: 1, deaths: 1 },
		'03-16-2020': { confirmed: 39, recovered: 1, deaths: 1 },
		'03-17-2020': { confirmed: 50, recovered: 2, deaths: 1 },
		'03-18-2020': { confirmed: 58, recovered: 2, deaths: 1 },
	},
	'Faroe Islands': {
		'03-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 2, recovered: 0, deaths: 0 },
	},
	Gibraltar: {
		'03-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 1, recovered: 1, deaths: 0 },
	},
	Liechtenstein: {
		'03-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 28, recovered: 0, deaths: 0 },
	},
	Poland: {
		'03-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 16, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 22, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 31, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 49, recovered: 0, deaths: 1 },
		'03-13-2020': { confirmed: 68, recovered: 0, deaths: 2 },
		'03-14-2020': { confirmed: 103, recovered: 0, deaths: 3 },
		'03-15-2020': { confirmed: 119, recovered: 0, deaths: 3 },
		'03-16-2020': { confirmed: 177, recovered: 13, deaths: 4 },
		'03-17-2020': { confirmed: 238, recovered: 13, deaths: 5 },
		'03-18-2020': { confirmed: 251, recovered: 13, deaths: 5 },
	},
	Tunisia: {
		'03-04-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 16, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 18, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 18, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 20, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 24, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 29, recovered: 0, deaths: 0 },
	},
	Palestine: {
		'03-05-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 16, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 22, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 22, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 22, recovered: 0, deaths: 0 },
	},
	'Bosnia and Herzegovina': {
		'03-05-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 18, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 24, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 25, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 26, recovered: 2, deaths: 0 },
		'03-18-2020': { confirmed: 38, recovered: 2, deaths: 0 },
	},
	Slovenia: {
		'03-05-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 16, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 16, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 31, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 57, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 89, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 141, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 181, recovered: 0, deaths: 1 },
		'03-15-2020': { confirmed: 219, recovered: 0, deaths: 1 },
		'03-16-2020': { confirmed: 253, recovered: 0, deaths: 1 },
		'03-17-2020': { confirmed: 275, recovered: 0, deaths: 1 },
		'03-18-2020': { confirmed: 275, recovered: 0, deaths: 1 },
	},
	'South Africa': {
		'03-05-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 17, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 24, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 38, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 51, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 62, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 62, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 116, recovered: 0, deaths: 0 },
	},
	Bhutan: {
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 1, recovered: 0, deaths: 0 },
	},
	Cameroon: {
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 10, recovered: 0, deaths: 0 },
	},
	'Costa Rica': {
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 9, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 9, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 22, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 23, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 26, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 27, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 35, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 41, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 50, recovered: 0, deaths: 0 },
	},
	Peru: {
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 15, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 28, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 38, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 43, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 86, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 117, recovered: 1, deaths: 0 },
		'03-18-2020': { confirmed: 145, recovered: 1, deaths: 0 },
	},
	Serbia: {
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 19, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 35, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 46, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 48, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 55, recovered: 1, deaths: 0 },
		'03-17-2020': { confirmed: 65, recovered: 1, deaths: 0 },
		'03-18-2020': { confirmed: 83, recovered: 1, deaths: 0 },
	},
	Slovakia: {
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 16, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 32, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 44, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 54, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 63, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 72, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 105, recovered: 0, deaths: 1 },
	},
	Togo: {
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 1, recovered: 0, deaths: 0 },
	},
	'Vatican City': {
		'03-06-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-07-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
	},
	'French Guiana': {
		'03-07-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 11, recovered: 0, deaths: 0 },
	},
	Malta: {
		'03-07-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 12, recovered: 1, deaths: 0 },
		'03-14-2020': { confirmed: 18, recovered: 1, deaths: 0 },
		'03-15-2020': { confirmed: 21, recovered: 1, deaths: 0 },
		'03-16-2020': { confirmed: 30, recovered: 2, deaths: 0 },
		'03-17-2020': { confirmed: 38, recovered: 2, deaths: 0 },
		'03-18-2020': { confirmed: 38, recovered: 2, deaths: 0 },
	},
	Martinique: {
		'03-07-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-08-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 9, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 9, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 15, recovered: 0, deaths: 1 },
		'03-17-2020': { confirmed: 16, recovered: 0, deaths: 1 },
		'03-18-2020': { confirmed: 19, recovered: 0, deaths: 1 },
	},
	'Republic of Ireland': {
		'03-08-2020': { confirmed: 21, recovered: 0, deaths: 0 },
	},
	Bulgaria: {
		'03-08-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 7, recovered: 0, deaths: 1 },
		'03-12-2020': { confirmed: 7, recovered: 0, deaths: 1 },
		'03-13-2020': { confirmed: 23, recovered: 0, deaths: 1 },
		'03-14-2020': { confirmed: 41, recovered: 0, deaths: 2 },
		'03-15-2020': { confirmed: 51, recovered: 0, deaths: 2 },
		'03-16-2020': { confirmed: 52, recovered: 0, deaths: 2 },
		'03-17-2020': { confirmed: 67, recovered: 0, deaths: 2 },
		'03-18-2020': { confirmed: 92, recovered: 0, deaths: 2 },
	},
	Maldives: {
		'03-08-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 9, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 13, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 13, recovered: 0, deaths: 0 },
	},
	Bangladesh: {
		'03-08-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 8, recovered: 2, deaths: 0 },
		'03-17-2020': { confirmed: 10, recovered: 3, deaths: 0 },
		'03-18-2020': { confirmed: 14, recovered: 3, deaths: 1 },
	},
	Moldova: {
		'03-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 12, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 23, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 23, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 30, recovered: 1, deaths: 0 },
		'03-18-2020': { confirmed: 30, recovered: 1, deaths: 1 },
	},
	Paraguay: {
		'03-08-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 9, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 11, recovered: 0, deaths: 0 },
	},
	Albania: {
		'03-09-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 12, recovered: 0, deaths: 1 },
		'03-12-2020': { confirmed: 23, recovered: 0, deaths: 1 },
		'03-13-2020': { confirmed: 33, recovered: 0, deaths: 1 },
		'03-14-2020': { confirmed: 38, recovered: 0, deaths: 1 },
		'03-15-2020': { confirmed: 42, recovered: 0, deaths: 1 },
		'03-16-2020': { confirmed: 51, recovered: 0, deaths: 1 },
		'03-17-2020': { confirmed: 55, recovered: 0, deaths: 1 },
		'03-18-2020': { confirmed: 59, recovered: 0, deaths: 2 },
	},
	Cyprus: {
		'03-09-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 14, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 26, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 26, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 33, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 46, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 49, recovered: 0, deaths: 0 },
	},
	'St. Martin': { '03-09-2020': { confirmed: 2, recovered: 0, deaths: 0 } },
	Brunei: {
		'03-09-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 37, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 40, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 50, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 54, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 56, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 68, recovered: 0, deaths: 0 },
	},
	'Iran (Islamic Republic of)': {
		'03-10-2020': { confirmed: 8042, recovered: 2731, deaths: 291 },
	},
	'Republic of Korea': {
		'03-10-2020': { confirmed: 7513, recovered: 247, deaths: 54 },
	},
	'Hong Kong SAR': {
		'03-10-2020': { confirmed: 120, recovered: 65, deaths: 3 },
	},
	'Taipei and environs': {
		'03-10-2020': { confirmed: 47, recovered: 17, deaths: 1 },
	},
	'Viet Nam': { '03-10-2020': { confirmed: 31, recovered: 16, deaths: 0 } },
	'occupied Palestinian territory': {
		'03-10-2020': { confirmed: 25, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 0, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 0, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 0, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 0, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 0, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 0, recovered: 0, deaths: 0 },
	},
	'Macao SAR': { '03-10-2020': { confirmed: 10, recovered: 10, deaths: 0 } },
	'Russian Federation': {
		'03-10-2020': { confirmed: 10, recovered: 3, deaths: 0 },
	},
	'Republic of Moldova': {
		'03-10-2020': { confirmed: 3, recovered: 0, deaths: 0 },
	},
	'Saint Martin': { '03-10-2020': { confirmed: 2, recovered: 0, deaths: 0 } },
	'Burkina Faso': {
		'03-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 15, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 15, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 20, recovered: 0, deaths: 1 },
	},
	'Channel Islands': {
		'03-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
	},
	'Holy See': {
		'03-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 1, recovered: 0, deaths: 0 },
	},
	Mongolia: {
		'03-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 6, recovered: 0, deaths: 0 },
	},
	Panama: {
		'03-10-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-11-2020': { confirmed: 8, recovered: 0, deaths: 1 },
		'03-12-2020': { confirmed: 11, recovered: 0, deaths: 1 },
		'03-13-2020': { confirmed: 27, recovered: 0, deaths: 1 },
		'03-14-2020': { confirmed: 36, recovered: 0, deaths: 1 },
		'03-15-2020': { confirmed: 43, recovered: 0, deaths: 1 },
		'03-16-2020': { confirmed: 55, recovered: 0, deaths: 1 },
		'03-17-2020': { confirmed: 69, recovered: 0, deaths: 1 },
		'03-18-2020': { confirmed: 86, recovered: 0, deaths: 1 },
	},
	China: {
		'03-11-2020': { confirmed: 80921, recovered: 61644, deaths: 3161 },
		'03-12-2020': { confirmed: 80932, recovered: 62901, deaths: 3172 },
		'03-13-2020': { confirmed: 80945, recovered: 64196, deaths: 3180 },
		'03-14-2020': { confirmed: 80977, recovered: 65660, deaths: 3193 },
		'03-15-2020': { confirmed: 81003, recovered: 67017, deaths: 3203 },
		'03-16-2020': { confirmed: 81033, recovered: 67910, deaths: 3217 },
		'03-17-2020': { confirmed: 81058, recovered: 68798, deaths: 3230 },
		'03-18-2020': { confirmed: 81102, recovered: 69755, deaths: 3241 },
	},
	'Korea, South': {
		'03-11-2020': { confirmed: 7755, recovered: 288, deaths: 60 },
		'03-12-2020': { confirmed: 7869, recovered: 333, deaths: 66 },
		'03-13-2020': { confirmed: 7979, recovered: 510, deaths: 66 },
		'03-14-2020': { confirmed: 8086, recovered: 510, deaths: 72 },
		'03-15-2020': { confirmed: 8162, recovered: 510, deaths: 75 },
		'03-16-2020': { confirmed: 8236, recovered: 1137, deaths: 75 },
		'03-17-2020': { confirmed: 8320, recovered: 1407, deaths: 81 },
		'03-18-2020': { confirmed: 8413, recovered: 1540, deaths: 84 },
	},
	'Cruise Ship': {
		'03-11-2020': { confirmed: 696, recovered: 325, deaths: 7 },
		'03-12-2020': { confirmed: 696, recovered: 325, deaths: 7 },
		'03-13-2020': { confirmed: 696, recovered: 325, deaths: 7 },
		'03-14-2020': { confirmed: 696, recovered: 325, deaths: 7 },
		'03-15-2020': { confirmed: 696, recovered: 325, deaths: 7 },
		'03-16-2020': { confirmed: 696, recovered: 325, deaths: 7 },
		'03-17-2020': { confirmed: 696, recovered: 325, deaths: 7 },
		'03-18-2020': { confirmed: 712, recovered: 325, deaths: 7 },
	},
	'United Kingdom': {
		'03-11-2020': { confirmed: 459, recovered: 19, deaths: 8 },
		'03-12-2020': { confirmed: 459, recovered: 19, deaths: 8 },
		'03-13-2020': { confirmed: 801, recovered: 19, deaths: 8 },
		'03-14-2020': { confirmed: 1143, recovered: 19, deaths: 21 },
		'03-15-2020': { confirmed: 1144, recovered: 19, deaths: 21 },
		'03-16-2020': { confirmed: 1551, recovered: 21, deaths: 56 },
		'03-17-2020': { confirmed: 1960, recovered: 53, deaths: 56 },
		'03-18-2020': { confirmed: 2642, recovered: 67, deaths: 72 },
	},
	Czechia: {
		'03-11-2020': { confirmed: 91, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 94, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 141, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 189, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 253, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 298, recovered: 3, deaths: 0 },
		'03-17-2020': { confirmed: 396, recovered: 3, deaths: 0 },
		'03-18-2020': { confirmed: 464, recovered: 3, deaths: 0 },
	},
	'Taiwan*': {
		'03-11-2020': { confirmed: 48, recovered: 17, deaths: 1 },
		'03-12-2020': { confirmed: 49, recovered: 20, deaths: 1 },
		'03-13-2020': { confirmed: 50, recovered: 20, deaths: 1 },
		'03-14-2020': { confirmed: 53, recovered: 20, deaths: 1 },
		'03-15-2020': { confirmed: 59, recovered: 20, deaths: 1 },
		'03-16-2020': { confirmed: 67, recovered: 20, deaths: 1 },
		'03-17-2020': { confirmed: 77, recovered: 22, deaths: 1 },
		'03-18-2020': { confirmed: 100, recovered: 22, deaths: 1 },
	},
	Bolivia: {
		'03-11-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 11, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 12, recovered: 0, deaths: 0 },
	},
	Honduras: {
		'03-11-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 9, recovered: 0, deaths: 0 },
	},
	'Congo (Kinshasa)': {
		'03-11-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 4, recovered: 0, deaths: 0 },
	},
	"Cote d'Ivoire": {
		'03-11-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 5, recovered: 1, deaths: 0 },
		'03-18-2020': { confirmed: 6, recovered: 1, deaths: 0 },
	},
	Jamaica: {
		'03-11-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 10, recovered: 2, deaths: 0 },
		'03-17-2020': { confirmed: 12, recovered: 2, deaths: 0 },
		'03-18-2020': { confirmed: 13, recovered: 2, deaths: 0 },
	},
	Reunion: {
		'03-11-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 9, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 9, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 12, recovered: 0, deaths: 0 },
	},
	Turkey: {
		'03-11-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-12-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 18, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 47, recovered: 0, deaths: 1 },
		'03-18-2020': { confirmed: 98, recovered: 0, deaths: 1 },
	},
	Cuba: {
		'03-12-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-13-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 7, recovered: 0, deaths: 1 },
	},
	Guyana: {
		'03-12-2020': { confirmed: 1, recovered: 0, deaths: 1 },
		'03-13-2020': { confirmed: 1, recovered: 0, deaths: 1 },
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 1 },
		'03-15-2020': { confirmed: 4, recovered: 0, deaths: 1 },
		'03-16-2020': { confirmed: 4, recovered: 0, deaths: 1 },
		'03-17-2020': { confirmed: 7, recovered: 0, deaths: 1 },
		'03-18-2020': { confirmed: 7, recovered: 0, deaths: 1 },
	},
	Kazakhstan: {
		'03-13-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 9, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 33, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 35, recovered: 0, deaths: 0 },
	},
	'Cayman Islands': {
		'03-13-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
	},
	Guadeloupe: {
		'03-13-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 18, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 27, recovered: 0, deaths: 0 },
	},
	Ethiopia: {
		'03-13-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 6, recovered: 0, deaths: 0 },
	},
	Sudan: {
		'03-13-2020': { confirmed: 1, recovered: 0, deaths: 1 },
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 1 },
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 1 },
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 1 },
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 1 },
		'03-18-2020': { confirmed: 2, recovered: 0, deaths: 1 },
	},
	Guinea: {
		'03-13-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 1, recovered: 0, deaths: 0 },
	},
	'Antigua and Barbuda': {
		'03-13-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 1, recovered: 0, deaths: 0 },
	},
	Aruba: {
		'03-13-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 4, recovered: 0, deaths: 0 },
	},
	Kenya: {
		'03-13-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 3, recovered: 0, deaths: 0 },
	},
	Uruguay: {
		'03-14-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 8, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 29, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 50, recovered: 0, deaths: 0 },
	},
	Ghana: {
		'03-14-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 7, recovered: 0, deaths: 0 },
	},
	Jersey: {
		'03-14-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 0, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 0, recovered: 0, deaths: 0 },
	},
	Namibia: {
		'03-14-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 2, recovered: 0, deaths: 0 },
	},
	Seychelles: {
		'03-14-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 4, recovered: 0, deaths: 0 },
	},
	'Trinidad and Tobago': {
		'03-14-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 4, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 7, recovered: 0, deaths: 0 },
	},
	Venezuela: {
		'03-14-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 17, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 33, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 36, recovered: 0, deaths: 0 },
	},
	Curacao: {
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
	},
	Eswatini: {
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 1, recovered: 0, deaths: 0 },
	},
	Gabon: {
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 1, recovered: 0, deaths: 0 },
	},
	Guatemala: {
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 2, recovered: 0, deaths: 1 },
		'03-17-2020': { confirmed: 6, recovered: 0, deaths: 1 },
		'03-18-2020': { confirmed: 6, recovered: 0, deaths: 1 },
	},
	Guernsey: {
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 0, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 0, recovered: 0, deaths: 0 },
	},
	Mauritania: {
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 1, recovered: 0, deaths: 0 },
	},
	Rwanda: {
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 5, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 7, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 8, recovered: 0, deaths: 0 },
	},
	'Saint Lucia': {
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 2, recovered: 0, deaths: 0 },
	},
	'Saint Vincent and the Grenadines': {
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 1, recovered: 0, deaths: 0 },
	},
	Suriname: {
		'03-14-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 1, recovered: 0, deaths: 0 },
	},
	Kosovo: {
		'03-15-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 2, recovered: 0, deaths: 0 },
	},
	'Central African Republic': {
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 1, recovered: 0, deaths: 0 },
	},
	'Congo (Brazzaville)': {
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 1, recovered: 0, deaths: 0 },
	},
	'Equatorial Guinea': {
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 4, recovered: 0, deaths: 0 },
	},
	Uzbekistan: {
		'03-15-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-16-2020': { confirmed: 6, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 10, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 15, recovered: 0, deaths: 0 },
	},
	Guam: {
		'03-16-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 0, recovered: 0, deaths: 0 },
	},
	'Puerto Rico': {
		'03-16-2020': { confirmed: 3, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 0, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 0, recovered: 0, deaths: 0 },
	},
	Benin: {
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 2, recovered: 0, deaths: 0 },
	},
	Greenland: {
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 1, recovered: 0, deaths: 0 },
	},
	Liberia: {
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 2, recovered: 0, deaths: 0 },
	},
	Mayotte: {
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 3, recovered: 0, deaths: 0 },
	},
	'Republic of the Congo': {
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 0, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 0, recovered: 0, deaths: 0 },
	},
	Somalia: {
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 1, recovered: 0, deaths: 0 },
	},
	Tanzania: {
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 3, recovered: 0, deaths: 0 },
	},
	'The Bahamas': {
		'03-16-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 1, recovered: 0, deaths: 0 },
	},
	Barbados: {
		'03-17-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 2, recovered: 0, deaths: 0 },
	},
	Montenegro: {
		'03-17-2020': { confirmed: 2, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 1, recovered: 0, deaths: 0 },
	},
	'The Gambia': {
		'03-17-2020': { confirmed: 1, recovered: 0, deaths: 0 },
		'03-18-2020': { confirmed: 0, recovered: 0, deaths: 0 },
	},
	Kyrgyzstan: { '03-18-2020': { confirmed: 3, recovered: 0, deaths: 0 } },
	Mauritius: { '03-18-2020': { confirmed: 3, recovered: 0, deaths: 0 } },
	Zambia: { '03-18-2020': { confirmed: 2, recovered: 0, deaths: 0 } },
	Djibouti: { '03-18-2020': { confirmed: 1, recovered: 0, deaths: 0 } },
	'Gambia, The': { '03-18-2020': { confirmed: 1, recovered: 0, deaths: 0 } },
};
