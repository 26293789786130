//import styled from 'styled-components';

//export const StyledHeader = styled.div`
  //text-align: center;
//`;
//export const StyledContent = styled.div`
  //margin-left: auto;
  //margin-right: auto;
  //max-width: 60rem;
//`;
import styled from 'styled-components';

export const StyledContent = styled.div`
	background: #fff;
	padding: 24px;
	min-height: 280px;
`;
